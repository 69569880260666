@charset "UTF-8";
/*
 *
 *   EGRET - Angular Material Design Admin Template
 *
 *
*/
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~hopscotch/dist/css/hopscotch.min.css';
@import '../../vendor/pace/pace-theme-min.css';
/* Colors for the ripple elements.*/
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable-next-line material/theme-mixin-api */
/* stylelint-disable material/no-prefixes */
/* stylelint-enable */
.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1, .mat-headline, .mat-typography h1 {
  font: 400 24px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3, .mat-subheading-2, .mat-typography h3 {
  font: 400 16px/28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4, .mat-subheading-1, .mat-typography h4 {
  font: 400 15px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5, .mat-typography h5 {
  font: 400 calc(14px * 0.83)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6, .mat-typography h6 {
  font: 400 calc(14px * 0.67)/20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong, .mat-body-2 {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body, .mat-body-1, .mat-typography {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p, .mat-body-1 p, .mat-typography p {
  margin: 0 0 12px;
}

.mat-small, .mat-caption {
  font: 400 12px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4, .mat-typography .mat-display-4 {
  font: 300 112px/112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3, .mat-typography .mat-display-3 {
  font: 400 56px/56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2, .mat-typography .mat-display-2 {
  font: 400 45px/48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1, .mat-typography .mat-display-1 {
  font: 400 34px/40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  -ms-transform: translateY(-1.28125em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  -ms-transform: translateY(-1.28124em) scale(0.75);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  -ms-transform: translateY(-1.28123em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px/24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale(0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
@media screen and (-ms-high-contrast: active) {
  .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
    opacity: 0.6;
  }
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop, .cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

@keyframes cdk-text-field-autofill-start {
  /*!*/
}
@keyframes cdk-text-field-autofill-end {
  /*!*/
}
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

.mat-focus-indicator {
  position: relative;
}

.mat-mdc-focus-indicator {
  position: relative;
}

.egret-indigo {
  /* White Background */
  /* Hopscotch Tour */
  /* up arrow  */
  /* right arrow */
  /* bottom arrow */
  /* Left Arrow */
}
.egret-indigo .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.egret-indigo .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-option:hover:not(.mat-option-disabled), .egret-indigo .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.egret-indigo .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.egret-indigo .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #3f51b5;
}
.egret-indigo .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffc107;
}
.egret-indigo .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.egret-indigo .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.egret-indigo .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.egret-indigo .mat-primary .mat-pseudo-checkbox-checked,
.egret-indigo .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #3f51b5;
}
.egret-indigo .mat-pseudo-checkbox-checked,
.egret-indigo .mat-pseudo-checkbox-indeterminate,
.egret-indigo .mat-accent .mat-pseudo-checkbox-checked,
.egret-indigo .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffc107;
}
.egret-indigo .mat-warn .mat-pseudo-checkbox-checked,
.egret-indigo .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.egret-indigo .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.egret-indigo .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.egret-indigo .mat-app-background, .egret-indigo.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.egret-indigo .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.egret-indigo .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-badge-content {
  color: white;
  background: #3f51b5;
}
.cdk-high-contrast-active .egret-indigo .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.egret-indigo .mat-badge-accent .mat-badge-content {
  background: #ffc107;
  color: black;
}
.egret-indigo .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.egret-indigo .mat-badge {
  position: relative;
}
.egret-indigo .mat-badge-hidden .mat-badge-content {
  display: none;
}
.egret-indigo .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.egret-indigo .ng-animate-disabled .mat-badge-content,
.egret-indigo .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.egret-indigo .mat-badge-content.mat-badge-active {
  transform: none;
}
.egret-indigo .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.egret-indigo .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.egret-indigo .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.egret-indigo .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .egret-indigo .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.egret-indigo .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .egret-indigo .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.egret-indigo .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .egret-indigo .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.egret-indigo .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .egret-indigo .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.egret-indigo .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.egret-indigo .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.egret-indigo .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.egret-indigo .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .egret-indigo .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.egret-indigo .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .egret-indigo .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.egret-indigo .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .egret-indigo .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.egret-indigo .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .egret-indigo .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.egret-indigo .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.egret-indigo .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.egret-indigo .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.egret-indigo .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .egret-indigo .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.egret-indigo .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .egret-indigo .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.egret-indigo .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .egret-indigo .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.egret-indigo .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .egret-indigo .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.egret-indigo .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-button, .egret-indigo .mat-icon-button, .egret-indigo .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.egret-indigo .mat-button.mat-primary, .egret-indigo .mat-icon-button.mat-primary, .egret-indigo .mat-stroked-button.mat-primary {
  color: #3f51b5;
}
.egret-indigo .mat-button.mat-accent, .egret-indigo .mat-icon-button.mat-accent, .egret-indigo .mat-stroked-button.mat-accent {
  color: #ffc107;
}
.egret-indigo .mat-button.mat-warn, .egret-indigo .mat-icon-button.mat-warn, .egret-indigo .mat-stroked-button.mat-warn {
  color: #f44336;
}
.egret-indigo .mat-button.mat-primary.mat-button-disabled, .egret-indigo .mat-button.mat-accent.mat-button-disabled, .egret-indigo .mat-button.mat-warn.mat-button-disabled, .egret-indigo .mat-button.mat-button-disabled.mat-button-disabled, .egret-indigo .mat-icon-button.mat-primary.mat-button-disabled, .egret-indigo .mat-icon-button.mat-accent.mat-button-disabled, .egret-indigo .mat-icon-button.mat-warn.mat-button-disabled, .egret-indigo .mat-icon-button.mat-button-disabled.mat-button-disabled, .egret-indigo .mat-stroked-button.mat-primary.mat-button-disabled, .egret-indigo .mat-stroked-button.mat-accent.mat-button-disabled, .egret-indigo .mat-stroked-button.mat-warn.mat-button-disabled, .egret-indigo .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.egret-indigo .mat-button.mat-primary .mat-button-focus-overlay, .egret-indigo .mat-icon-button.mat-primary .mat-button-focus-overlay, .egret-indigo .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #3f51b5;
}
.egret-indigo .mat-button.mat-accent .mat-button-focus-overlay, .egret-indigo .mat-icon-button.mat-accent .mat-button-focus-overlay, .egret-indigo .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffc107;
}
.egret-indigo .mat-button.mat-warn .mat-button-focus-overlay, .egret-indigo .mat-icon-button.mat-warn .mat-button-focus-overlay, .egret-indigo .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.egret-indigo .mat-button.mat-button-disabled .mat-button-focus-overlay, .egret-indigo .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .egret-indigo .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.egret-indigo .mat-button .mat-ripple-element, .egret-indigo .mat-icon-button .mat-ripple-element, .egret-indigo .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.egret-indigo .mat-button-focus-overlay {
  background: black;
}
.egret-indigo .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-flat-button, .egret-indigo .mat-raised-button, .egret-indigo .mat-fab, .egret-indigo .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.egret-indigo .mat-flat-button.mat-primary, .egret-indigo .mat-raised-button.mat-primary, .egret-indigo .mat-fab.mat-primary, .egret-indigo .mat-mini-fab.mat-primary {
  color: white;
}
.egret-indigo .mat-flat-button.mat-accent, .egret-indigo .mat-raised-button.mat-accent, .egret-indigo .mat-fab.mat-accent, .egret-indigo .mat-mini-fab.mat-accent {
  color: black;
}
.egret-indigo .mat-flat-button.mat-warn, .egret-indigo .mat-raised-button.mat-warn, .egret-indigo .mat-fab.mat-warn, .egret-indigo .mat-mini-fab.mat-warn {
  color: white;
}
.egret-indigo .mat-flat-button.mat-primary.mat-button-disabled, .egret-indigo .mat-flat-button.mat-accent.mat-button-disabled, .egret-indigo .mat-flat-button.mat-warn.mat-button-disabled, .egret-indigo .mat-flat-button.mat-button-disabled.mat-button-disabled, .egret-indigo .mat-raised-button.mat-primary.mat-button-disabled, .egret-indigo .mat-raised-button.mat-accent.mat-button-disabled, .egret-indigo .mat-raised-button.mat-warn.mat-button-disabled, .egret-indigo .mat-raised-button.mat-button-disabled.mat-button-disabled, .egret-indigo .mat-fab.mat-primary.mat-button-disabled, .egret-indigo .mat-fab.mat-accent.mat-button-disabled, .egret-indigo .mat-fab.mat-warn.mat-button-disabled, .egret-indigo .mat-fab.mat-button-disabled.mat-button-disabled, .egret-indigo .mat-mini-fab.mat-primary.mat-button-disabled, .egret-indigo .mat-mini-fab.mat-accent.mat-button-disabled, .egret-indigo .mat-mini-fab.mat-warn.mat-button-disabled, .egret-indigo .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.egret-indigo .mat-flat-button.mat-primary, .egret-indigo .mat-raised-button.mat-primary, .egret-indigo .mat-fab.mat-primary, .egret-indigo .mat-mini-fab.mat-primary {
  background-color: #3f51b5;
}
.egret-indigo .mat-flat-button.mat-accent, .egret-indigo .mat-raised-button.mat-accent, .egret-indigo .mat-fab.mat-accent, .egret-indigo .mat-mini-fab.mat-accent {
  background-color: #ffc107;
}
.egret-indigo .mat-flat-button.mat-warn, .egret-indigo .mat-raised-button.mat-warn, .egret-indigo .mat-fab.mat-warn, .egret-indigo .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.egret-indigo .mat-flat-button.mat-primary.mat-button-disabled, .egret-indigo .mat-flat-button.mat-accent.mat-button-disabled, .egret-indigo .mat-flat-button.mat-warn.mat-button-disabled, .egret-indigo .mat-flat-button.mat-button-disabled.mat-button-disabled, .egret-indigo .mat-raised-button.mat-primary.mat-button-disabled, .egret-indigo .mat-raised-button.mat-accent.mat-button-disabled, .egret-indigo .mat-raised-button.mat-warn.mat-button-disabled, .egret-indigo .mat-raised-button.mat-button-disabled.mat-button-disabled, .egret-indigo .mat-fab.mat-primary.mat-button-disabled, .egret-indigo .mat-fab.mat-accent.mat-button-disabled, .egret-indigo .mat-fab.mat-warn.mat-button-disabled, .egret-indigo .mat-fab.mat-button-disabled.mat-button-disabled, .egret-indigo .mat-mini-fab.mat-primary.mat-button-disabled, .egret-indigo .mat-mini-fab.mat-accent.mat-button-disabled, .egret-indigo .mat-mini-fab.mat-warn.mat-button-disabled, .egret-indigo .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-flat-button.mat-primary .mat-ripple-element, .egret-indigo .mat-raised-button.mat-primary .mat-ripple-element, .egret-indigo .mat-fab.mat-primary .mat-ripple-element, .egret-indigo .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-indigo .mat-flat-button.mat-accent .mat-ripple-element, .egret-indigo .mat-raised-button.mat-accent .mat-ripple-element, .egret-indigo .mat-fab.mat-accent .mat-ripple-element, .egret-indigo .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.egret-indigo .mat-flat-button.mat-warn .mat-ripple-element, .egret-indigo .mat-raised-button.mat-warn .mat-ripple-element, .egret-indigo .mat-fab.mat-warn .mat-ripple-element, .egret-indigo .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-indigo .mat-stroked-button:not([class*=mat-elevation-z]), .egret-indigo .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-fab:not([class*=mat-elevation-z]), .egret-indigo .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .egret-indigo .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .egret-indigo .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-button-toggle-standalone,
.egret-indigo .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.egret-indigo .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.egret-indigo .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.egret-indigo .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.egret-indigo .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
.egret-indigo [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.egret-indigo .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.egret-indigo .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.egret-indigo .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.egret-indigo .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.egret-indigo .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-checkbox-checkmark {
  fill: #fafafa;
}
.egret-indigo .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.egret-indigo .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.egret-indigo .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .egret-indigo .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #3f51b5;
}
.egret-indigo .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .egret-indigo .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffc107;
}
.egret-indigo .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .egret-indigo .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.egret-indigo .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .egret-indigo .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.egret-indigo .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.egret-indigo .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.egret-indigo .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.egret-indigo .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #3f51b5;
}
.egret-indigo .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.egret-indigo .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffc107;
}
.egret-indigo .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.egret-indigo .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.egret-indigo .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.egret-indigo .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.egret-indigo .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.egret-indigo .mat-chip.mat-standard-chip::after {
  background: black;
}
.egret-indigo .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #3f51b5;
  color: white;
}
.egret-indigo .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-indigo .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-indigo .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.egret-indigo .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-indigo .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-indigo .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffc107;
  color: black;
}
.egret-indigo .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.egret-indigo .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.egret-indigo .mat-table {
  background: white;
}
.egret-indigo .mat-table thead, .egret-indigo .mat-table tbody, .egret-indigo .mat-table tfoot,
.egret-indigo mat-header-row, .egret-indigo mat-row, .egret-indigo mat-footer-row,
.egret-indigo [mat-header-row], .egret-indigo [mat-row], .egret-indigo [mat-footer-row],
.egret-indigo .mat-table-sticky {
  background: inherit;
}
.egret-indigo mat-row, .egret-indigo mat-header-row, .egret-indigo mat-footer-row,
.egret-indigo th.mat-header-cell, .egret-indigo td.mat-cell, .egret-indigo td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-cell, .egret-indigo .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-datepicker-toggle,
.egret-indigo .mat-datepicker-content .mat-calendar-next-button,
.egret-indigo .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-calendar-body-cell-content,
.egret-indigo .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.egret-indigo .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.egret-indigo .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.egret-indigo .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.04);
}
.egret-indigo .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.egret-indigo .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.egret-indigo .mat-calendar-body-in-range::before {
  background: rgba(63, 81, 181, 0.2);
}
.egret-indigo .mat-calendar-body-comparison-identical,
.egret-indigo .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-indigo .mat-calendar-body-comparison-bridge-start::before,
.egret-indigo [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-indigo .mat-calendar-body-comparison-bridge-end::before,
.egret-indigo [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(63, 81, 181, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-indigo .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-indigo .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-indigo .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-indigo .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-indigo .mat-calendar-body-selected {
  background-color: #3f51b5;
  color: white;
}
.egret-indigo .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(63, 81, 181, 0.4);
}
.egret-indigo .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.egret-indigo .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 193, 7, 0.2);
}
.egret-indigo .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.egret-indigo .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-indigo .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.egret-indigo .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 193, 7, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-indigo .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.egret-indigo .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 193, 7, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-indigo .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-indigo .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-indigo .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-indigo .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-indigo .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffc107;
  color: black;
}
.egret-indigo .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 193, 7, 0.4);
}
.egret-indigo .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.egret-indigo .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.egret-indigo .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.egret-indigo .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-indigo .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.egret-indigo .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-indigo .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.egret-indigo .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-indigo .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-indigo .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-indigo .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-indigo .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-indigo .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.egret-indigo .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.egret-indigo .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.egret-indigo .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-datepicker-toggle-active {
  color: #3f51b5;
}
.egret-indigo .mat-datepicker-toggle-active.mat-accent {
  color: #ffc107;
}
.egret-indigo .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.egret-indigo .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .egret-indigo .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .egret-indigo .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .egret-indigo .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.egret-indigo .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-expansion-panel-header-description,
.egret-indigo .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.egret-indigo .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.egret-indigo .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.egret-indigo .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.egret-indigo .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.egret-indigo .mat-form-field.mat-focused .mat-form-field-label {
  color: #3f51b5;
}
.egret-indigo .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffc107;
}
.egret-indigo .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.egret-indigo .mat-focused .mat-form-field-required-marker {
  color: #ffc107;
}
.egret-indigo .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #3f51b5;
}
.egret-indigo .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffc107;
}
.egret-indigo .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.egret-indigo .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #3f51b5;
}
.egret-indigo .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffc107;
}
.egret-indigo .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.egret-indigo .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.egret-indigo .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.egret-indigo .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.egret-indigo .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.egret-indigo .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.egret-indigo .mat-error {
  color: #f44336;
}
.egret-indigo .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.egret-indigo .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.egret-indigo .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.egret-indigo .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.egret-indigo .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.egret-indigo .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.egret-indigo .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.egret-indigo .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.egret-indigo .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #3f51b5;
}
.egret-indigo .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffc107;
}
.egret-indigo .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.egret-indigo .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.egret-indigo .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.egret-indigo .mat-icon.mat-primary {
  color: #3f51b5;
}
.egret-indigo .mat-icon.mat-accent {
  color: #ffc107;
}
.egret-indigo .mat-icon.mat-warn {
  color: #f44336;
}
.egret-indigo .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-input-element:disabled,
.egret-indigo .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-input-element {
  caret-color: #3f51b5;
}
.egret-indigo .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.egret-indigo .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.egret-indigo .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.egret-indigo .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.egret-indigo .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffc107;
}
.egret-indigo .mat-form-field.mat-warn .mat-input-element,
.egret-indigo .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.egret-indigo .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.egret-indigo .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-list-item-disabled {
  background-color: #eeeeee;
}
.egret-indigo .mat-list-option:hover, .egret-indigo .mat-list-option:focus,
.egret-indigo .mat-nav-list .mat-list-item:hover,
.egret-indigo .mat-nav-list .mat-list-item:focus,
.egret-indigo .mat-action-list .mat-list-item:hover,
.egret-indigo .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.egret-indigo .mat-list-single-selected-option, .egret-indigo .mat-list-single-selected-option:hover, .egret-indigo .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-menu-panel {
  background: white;
}
.egret-indigo .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-menu-item[disabled], .egret-indigo .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-menu-item .mat-icon-no-color,
.egret-indigo .mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-menu-item:hover:not([disabled]),
.egret-indigo .mat-menu-item.cdk-program-focused:not([disabled]),
.egret-indigo .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.egret-indigo .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.egret-indigo .mat-paginator {
  background: white;
}
.egret-indigo .mat-paginator,
.egret-indigo .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-paginator-decrement,
.egret-indigo .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-paginator-first,
.egret-indigo .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-icon-button[disabled] .mat-paginator-decrement,
.egret-indigo .mat-icon-button[disabled] .mat-paginator-increment,
.egret-indigo .mat-icon-button[disabled] .mat-paginator-first,
.egret-indigo .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-paginator-container {
  min-height: 56px;
}

.egret-indigo .mat-progress-bar-background {
  fill: #c5cbe9;
}
.egret-indigo .mat-progress-bar-buffer {
  background-color: #c5cbe9;
}
.egret-indigo .mat-progress-bar-fill::after {
  background-color: #3f51b5;
}
.egret-indigo .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #ffecb5;
}
.egret-indigo .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #ffecb5;
}
.egret-indigo .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffc107;
}
.egret-indigo .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.egret-indigo .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.egret-indigo .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.egret-indigo .mat-progress-spinner circle, .egret-indigo .mat-spinner circle {
  stroke: #3f51b5;
}
.egret-indigo .mat-progress-spinner.mat-accent circle, .egret-indigo .mat-spinner.mat-accent circle {
  stroke: #ffc107;
}
.egret-indigo .mat-progress-spinner.mat-warn circle, .egret-indigo .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.egret-indigo .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #3f51b5;
}
.egret-indigo .mat-radio-button.mat-primary .mat-radio-inner-circle,
.egret-indigo .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-indigo .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .egret-indigo .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #3f51b5;
}
.egret-indigo .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffc107;
}
.egret-indigo .mat-radio-button.mat-accent .mat-radio-inner-circle,
.egret-indigo .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-indigo .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .egret-indigo .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffc107;
}
.egret-indigo .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.egret-indigo .mat-radio-button.mat-warn .mat-radio-inner-circle,
.egret-indigo .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-indigo .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .egret-indigo .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.egret-indigo .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.egret-indigo .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.egret-indigo .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.egret-indigo .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.egret-indigo .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-select-panel {
  background: white;
}
.egret-indigo .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #3f51b5;
}
.egret-indigo .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffc107;
}
.egret-indigo .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.egret-indigo .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.egret-indigo .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-drawer.mat-drawer-push {
  background-color: white;
}
.egret-indigo .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.egret-indigo [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.egret-indigo [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.egret-indigo .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffc107;
}
.egret-indigo .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 193, 7, 0.54);
}
.egret-indigo .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffc107;
}
.egret-indigo .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #3f51b5;
}
.egret-indigo .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(63, 81, 181, 0.54);
}
.egret-indigo .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #3f51b5;
}
.egret-indigo .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.egret-indigo .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.egret-indigo .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.egret-indigo .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.egret-indigo .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.egret-indigo .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.egret-indigo .mat-primary .mat-slider-track-fill,
.egret-indigo .mat-primary .mat-slider-thumb,
.egret-indigo .mat-primary .mat-slider-thumb-label {
  background-color: #3f51b5;
}
.egret-indigo .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.egret-indigo .mat-primary .mat-slider-focus-ring {
  background-color: rgba(63, 81, 181, 0.2);
}
.egret-indigo .mat-accent .mat-slider-track-fill,
.egret-indigo .mat-accent .mat-slider-thumb,
.egret-indigo .mat-accent .mat-slider-thumb-label {
  background-color: #ffc107;
}
.egret-indigo .mat-accent .mat-slider-thumb-label-text {
  color: black;
}
.egret-indigo .mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 193, 7, 0.2);
}
.egret-indigo .mat-warn .mat-slider-track-fill,
.egret-indigo .mat-warn .mat-slider-thumb,
.egret-indigo .mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.egret-indigo .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.egret-indigo .mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.egret-indigo .mat-slider:hover .mat-slider-track-background,
.egret-indigo .cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-slider-disabled .mat-slider-track-background,
.egret-indigo .mat-slider-disabled .mat-slider-track-fill,
.egret-indigo .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.egret-indigo .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.egret-indigo .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.egret-indigo .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.egret-indigo .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.egret-indigo .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.egret-indigo .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .egret-indigo .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .egret-indigo .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.egret-indigo .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.egret-indigo .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.egret-indigo .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.egret-indigo .mat-step-header.cdk-keyboard-focused, .egret-indigo .mat-step-header.cdk-program-focused, .egret-indigo .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .egret-indigo .mat-step-header:hover {
    background: none;
  }
}
.egret-indigo .mat-step-header .mat-step-label,
.egret-indigo .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.egret-indigo .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.egret-indigo .mat-step-header .mat-step-icon-selected,
.egret-indigo .mat-step-header .mat-step-icon-state-done,
.egret-indigo .mat-step-header .mat-step-icon-state-edit {
  background-color: #3f51b5;
  color: white;
}
.egret-indigo .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.egret-indigo .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.egret-indigo .mat-stepper-horizontal, .egret-indigo .mat-stepper-vertical {
  background-color: white;
}
.egret-indigo .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-horizontal-stepper-header::before,
.egret-indigo .mat-horizontal-stepper-header::after,
.egret-indigo .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.egret-indigo .mat-sort-header-arrow {
  color: #757575;
}
.egret-indigo .mat-tab-nav-bar,
.egret-indigo .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-tab-group-inverted-header .mat-tab-nav-bar,
.egret-indigo .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.egret-indigo .mat-tab-label, .egret-indigo .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-tab-label.mat-tab-disabled, .egret-indigo .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.egret-indigo .mat-tab-group[class*=mat-background-] .mat-tab-header,
.egret-indigo .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.egret-indigo .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 203, 233, 0.3);
}
.egret-indigo .mat-tab-group.mat-primary .mat-ink-bar, .egret-indigo .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #3f51b5;
}
.egret-indigo .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .egret-indigo .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.egret-indigo .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 236, 181, 0.3);
}
.egret-indigo .mat-tab-group.mat-accent .mat-ink-bar, .egret-indigo .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffc107;
}
.egret-indigo .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .egret-indigo .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: black;
}
.egret-indigo .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.egret-indigo .mat-tab-group.mat-warn .mat-ink-bar, .egret-indigo .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.egret-indigo .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .egret-indigo .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.egret-indigo .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(197, 203, 233, 0.3);
}
.egret-indigo .mat-tab-group.mat-background-primary .mat-tab-header, .egret-indigo .mat-tab-group.mat-background-primary .mat-tab-links, .egret-indigo .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .egret-indigo .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .egret-indigo .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .egret-indigo .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #3f51b5;
}
.egret-indigo .mat-tab-group.mat-background-primary .mat-tab-label, .egret-indigo .mat-tab-group.mat-background-primary .mat-tab-link, .egret-indigo .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .egret-indigo .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.egret-indigo .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .egret-indigo .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .egret-indigo .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .egret-indigo .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.egret-indigo .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .egret-indigo .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.egret-indigo .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-indigo .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.egret-indigo .mat-tab-group.mat-background-primary .mat-ripple-element, .egret-indigo .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-indigo .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 236, 181, 0.3);
}
.egret-indigo .mat-tab-group.mat-background-accent .mat-tab-header, .egret-indigo .mat-tab-group.mat-background-accent .mat-tab-links, .egret-indigo .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .egret-indigo .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .egret-indigo .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .egret-indigo .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #ffc107;
}
.egret-indigo .mat-tab-group.mat-background-accent .mat-tab-label, .egret-indigo .mat-tab-group.mat-background-accent .mat-tab-link, .egret-indigo .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .egret-indigo .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: black;
}
.egret-indigo .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .egret-indigo .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .egret-indigo .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .egret-indigo .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.egret-indigo .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .egret-indigo .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: black;
}
.egret-indigo .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-indigo .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.egret-indigo .mat-tab-group.mat-background-accent .mat-ripple-element, .egret-indigo .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-indigo .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-indigo .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.egret-indigo .mat-tab-group.mat-background-warn .mat-tab-header, .egret-indigo .mat-tab-group.mat-background-warn .mat-tab-links, .egret-indigo .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .egret-indigo .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .egret-indigo .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .egret-indigo .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #f44336;
}
.egret-indigo .mat-tab-group.mat-background-warn .mat-tab-label, .egret-indigo .mat-tab-group.mat-background-warn .mat-tab-link, .egret-indigo .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .egret-indigo .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.egret-indigo .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .egret-indigo .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .egret-indigo .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .egret-indigo .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.egret-indigo .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .egret-indigo .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.egret-indigo .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-indigo .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.egret-indigo .mat-tab-group.mat-background-warn .mat-ripple-element, .egret-indigo .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-indigo .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.egret-indigo .mat-toolbar.mat-primary {
  background: #3f51b5;
  color: white;
}
.egret-indigo .mat-toolbar.mat-accent {
  background: #ffc107;
  color: black;
}
.egret-indigo .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.egret-indigo .mat-toolbar .mat-form-field-underline,
.egret-indigo .mat-toolbar .mat-form-field-ripple,
.egret-indigo .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.egret-indigo .mat-toolbar .mat-form-field-label,
.egret-indigo .mat-toolbar .mat-focused .mat-form-field-label,
.egret-indigo .mat-toolbar .mat-select-value,
.egret-indigo .mat-toolbar .mat-select-arrow,
.egret-indigo .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.egret-indigo .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }

  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.egret-indigo .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.egret-indigo .mat-tree {
  background: white;
}
.egret-indigo .mat-tree-node,
.egret-indigo .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tree-node {
  min-height: 48px;
}

.egret-indigo .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-indigo .mat-simple-snackbar-action {
  color: #ffc107;
}
.egret-indigo .mat-bg-primary,
.egret-indigo .topbar:not(.topbar-white),
.egret-indigo .chats-wrap .conversations-hold .single-conversation.me .conversation-msg,
.egret-indigo .ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a,
.egret-indigo .fileupload-drop-zone.dz-file-over,
.egret-indigo .toolbar-avatar.online > .status-dot,
.egret-indigo .cal-open-day-events,
.egret-indigo div.hopscotch-bubble {
  background: #3f51b5 !important;
  color: #ffffff !important;
}
.egret-indigo .sidebar-panel .mat-nav-list .mat-list-item {
  color: rgba(0, 0, 0, 0.94);
}
.egret-indigo .topnav ul.menu > li > div.open > a,
.egret-indigo .topnav ul.menu > li > div.open > div,
.egret-indigo .sidebar-panel .sidebar-list-item.open > .mat-list-item-content > .sub-menu,
.egret-indigo .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.egret-indigo .list-item-active {
  border-color: #3f51b5 !important;
}
.egret-indigo .sidebar-compact ul.submenu,
.egret-indigo .default-bg {
  background: #ffffff !important;
}
.egret-indigo .topbar-white {
  background: #ffffff;
}
.egret-indigo .topbar-white .search-bar .top-search-form {
  box-shadow: inset 0 0 2px 2px rgba(136, 136, 136, 0.2);
}
.egret-indigo div.hopscotch-bubble {
  border-color: #344397;
}
.egret-indigo div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom: 17px solid #344397;
}
.egret-indigo div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
  border-bottom: 17px solid #3f51b5;
  top: -16px;
}
.egret-indigo div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left: 17px solid #344397;
}
.egret-indigo div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
  border-left: 17px solid #3f51b5;
  left: -1px;
}
.egret-indigo div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top: 17px solid #344397;
}
.egret-indigo div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
  border-top: 17px solid #3f51b5;
  top: -18px;
}
.egret-indigo div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
  border-right: 17px solid #344397;
}
.egret-indigo div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
  left: 1px;
  border-right: 17px solid #3f51b5;
}
.egret-indigo .mat-bg-accent,
.egret-indigo div.hopscotch-bubble .hopscotch-bubble-number,
.egret-indigo div.hopscotch-bubble .hopscotch-nav-button.next:hover,
.egret-indigo div.hopscotch-bubble .hopscotch-nav-button.next {
  background-color: #ffc107;
  color: black;
}
.egret-indigo .mat-bg-warn {
  background-color: #f44336;
  color: white;
}
.egret-indigo .mat-color-accent {
  color: #ffc107;
}
.egret-indigo .mat-color-warn {
  color: #f44336;
}
.egret-indigo .mat-color-default {
  color: rgba(0, 0, 0, 0.87);
}

.egret-blue {
  /* Hopscotch Tour */
  /* up arrow  */
  /* right arrow */
  /* bottom arrow */
  /* Left Arrow */
}
.egret-blue .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.egret-blue .mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-option:hover:not(.mat-option-disabled), .egret-blue .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.egret-blue .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.egret-blue .mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #247ba0;
}
.egret-blue .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #ffd97d;
}
.egret-blue .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.egret-blue .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-pseudo-checkbox::after {
  color: #fafafa;
}
.egret-blue .mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}
.egret-blue .mat-primary .mat-pseudo-checkbox-checked,
.egret-blue .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #247ba0;
}
.egret-blue .mat-pseudo-checkbox-checked,
.egret-blue .mat-pseudo-checkbox-indeterminate,
.egret-blue .mat-accent .mat-pseudo-checkbox-checked,
.egret-blue .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #ffd97d;
}
.egret-blue .mat-warn .mat-pseudo-checkbox-checked,
.egret-blue .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.egret-blue .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.egret-blue .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}
.egret-blue .mat-app-background, .egret-blue.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.egret-blue .mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.egret-blue .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-badge-content {
  color: white;
  background: #247ba0;
}
.cdk-high-contrast-active .egret-blue .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.egret-blue .mat-badge-accent .mat-badge-content {
  background: #ffd97d;
  color: black;
}
.egret-blue .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.egret-blue .mat-badge {
  position: relative;
}
.egret-blue .mat-badge-hidden .mat-badge-content {
  display: none;
}
.egret-blue .mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.egret-blue .ng-animate-disabled .mat-badge-content,
.egret-blue .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.egret-blue .mat-badge-content.mat-badge-active {
  transform: none;
}
.egret-blue .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.egret-blue .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.egret-blue .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.egret-blue .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .egret-blue .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.egret-blue .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .egret-blue .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.egret-blue .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .egret-blue .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.egret-blue .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .egret-blue .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.egret-blue .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.egret-blue .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.egret-blue .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.egret-blue .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .egret-blue .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.egret-blue .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .egret-blue .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.egret-blue .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .egret-blue .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.egret-blue .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .egret-blue .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.egret-blue .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.egret-blue .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.egret-blue .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.egret-blue .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .egret-blue .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.egret-blue .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .egret-blue .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.egret-blue .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .egret-blue .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.egret-blue .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .egret-blue .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.egret-blue .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-button, .egret-blue .mat-icon-button, .egret-blue .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.egret-blue .mat-button.mat-primary, .egret-blue .mat-icon-button.mat-primary, .egret-blue .mat-stroked-button.mat-primary {
  color: #247ba0;
}
.egret-blue .mat-button.mat-accent, .egret-blue .mat-icon-button.mat-accent, .egret-blue .mat-stroked-button.mat-accent {
  color: #ffd97d;
}
.egret-blue .mat-button.mat-warn, .egret-blue .mat-icon-button.mat-warn, .egret-blue .mat-stroked-button.mat-warn {
  color: #f44336;
}
.egret-blue .mat-button.mat-primary.mat-button-disabled, .egret-blue .mat-button.mat-accent.mat-button-disabled, .egret-blue .mat-button.mat-warn.mat-button-disabled, .egret-blue .mat-button.mat-button-disabled.mat-button-disabled, .egret-blue .mat-icon-button.mat-primary.mat-button-disabled, .egret-blue .mat-icon-button.mat-accent.mat-button-disabled, .egret-blue .mat-icon-button.mat-warn.mat-button-disabled, .egret-blue .mat-icon-button.mat-button-disabled.mat-button-disabled, .egret-blue .mat-stroked-button.mat-primary.mat-button-disabled, .egret-blue .mat-stroked-button.mat-accent.mat-button-disabled, .egret-blue .mat-stroked-button.mat-warn.mat-button-disabled, .egret-blue .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.egret-blue .mat-button.mat-primary .mat-button-focus-overlay, .egret-blue .mat-icon-button.mat-primary .mat-button-focus-overlay, .egret-blue .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #247ba0;
}
.egret-blue .mat-button.mat-accent .mat-button-focus-overlay, .egret-blue .mat-icon-button.mat-accent .mat-button-focus-overlay, .egret-blue .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #ffd97d;
}
.egret-blue .mat-button.mat-warn .mat-button-focus-overlay, .egret-blue .mat-icon-button.mat-warn .mat-button-focus-overlay, .egret-blue .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.egret-blue .mat-button.mat-button-disabled .mat-button-focus-overlay, .egret-blue .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .egret-blue .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.egret-blue .mat-button .mat-ripple-element, .egret-blue .mat-icon-button .mat-ripple-element, .egret-blue .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.egret-blue .mat-button-focus-overlay {
  background: black;
}
.egret-blue .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-flat-button, .egret-blue .mat-raised-button, .egret-blue .mat-fab, .egret-blue .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.egret-blue .mat-flat-button.mat-primary, .egret-blue .mat-raised-button.mat-primary, .egret-blue .mat-fab.mat-primary, .egret-blue .mat-mini-fab.mat-primary {
  color: white;
}
.egret-blue .mat-flat-button.mat-accent, .egret-blue .mat-raised-button.mat-accent, .egret-blue .mat-fab.mat-accent, .egret-blue .mat-mini-fab.mat-accent {
  color: black;
}
.egret-blue .mat-flat-button.mat-warn, .egret-blue .mat-raised-button.mat-warn, .egret-blue .mat-fab.mat-warn, .egret-blue .mat-mini-fab.mat-warn {
  color: white;
}
.egret-blue .mat-flat-button.mat-primary.mat-button-disabled, .egret-blue .mat-flat-button.mat-accent.mat-button-disabled, .egret-blue .mat-flat-button.mat-warn.mat-button-disabled, .egret-blue .mat-flat-button.mat-button-disabled.mat-button-disabled, .egret-blue .mat-raised-button.mat-primary.mat-button-disabled, .egret-blue .mat-raised-button.mat-accent.mat-button-disabled, .egret-blue .mat-raised-button.mat-warn.mat-button-disabled, .egret-blue .mat-raised-button.mat-button-disabled.mat-button-disabled, .egret-blue .mat-fab.mat-primary.mat-button-disabled, .egret-blue .mat-fab.mat-accent.mat-button-disabled, .egret-blue .mat-fab.mat-warn.mat-button-disabled, .egret-blue .mat-fab.mat-button-disabled.mat-button-disabled, .egret-blue .mat-mini-fab.mat-primary.mat-button-disabled, .egret-blue .mat-mini-fab.mat-accent.mat-button-disabled, .egret-blue .mat-mini-fab.mat-warn.mat-button-disabled, .egret-blue .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.egret-blue .mat-flat-button.mat-primary, .egret-blue .mat-raised-button.mat-primary, .egret-blue .mat-fab.mat-primary, .egret-blue .mat-mini-fab.mat-primary {
  background-color: #247ba0;
}
.egret-blue .mat-flat-button.mat-accent, .egret-blue .mat-raised-button.mat-accent, .egret-blue .mat-fab.mat-accent, .egret-blue .mat-mini-fab.mat-accent {
  background-color: #ffd97d;
}
.egret-blue .mat-flat-button.mat-warn, .egret-blue .mat-raised-button.mat-warn, .egret-blue .mat-fab.mat-warn, .egret-blue .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.egret-blue .mat-flat-button.mat-primary.mat-button-disabled, .egret-blue .mat-flat-button.mat-accent.mat-button-disabled, .egret-blue .mat-flat-button.mat-warn.mat-button-disabled, .egret-blue .mat-flat-button.mat-button-disabled.mat-button-disabled, .egret-blue .mat-raised-button.mat-primary.mat-button-disabled, .egret-blue .mat-raised-button.mat-accent.mat-button-disabled, .egret-blue .mat-raised-button.mat-warn.mat-button-disabled, .egret-blue .mat-raised-button.mat-button-disabled.mat-button-disabled, .egret-blue .mat-fab.mat-primary.mat-button-disabled, .egret-blue .mat-fab.mat-accent.mat-button-disabled, .egret-blue .mat-fab.mat-warn.mat-button-disabled, .egret-blue .mat-fab.mat-button-disabled.mat-button-disabled, .egret-blue .mat-mini-fab.mat-primary.mat-button-disabled, .egret-blue .mat-mini-fab.mat-accent.mat-button-disabled, .egret-blue .mat-mini-fab.mat-warn.mat-button-disabled, .egret-blue .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-flat-button.mat-primary .mat-ripple-element, .egret-blue .mat-raised-button.mat-primary .mat-ripple-element, .egret-blue .mat-fab.mat-primary .mat-ripple-element, .egret-blue .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-blue .mat-flat-button.mat-accent .mat-ripple-element, .egret-blue .mat-raised-button.mat-accent .mat-ripple-element, .egret-blue .mat-fab.mat-accent .mat-ripple-element, .egret-blue .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.egret-blue .mat-flat-button.mat-warn .mat-ripple-element, .egret-blue .mat-raised-button.mat-warn .mat-ripple-element, .egret-blue .mat-fab.mat-warn .mat-ripple-element, .egret-blue .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-blue .mat-stroked-button:not([class*=mat-elevation-z]), .egret-blue .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-fab:not([class*=mat-elevation-z]), .egret-blue .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .egret-blue .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .egret-blue .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-button-toggle-standalone,
.egret-blue .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.egret-blue .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.egret-blue .mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.egret-blue .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}
.egret-blue .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
}
.egret-blue [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.egret-blue .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.egret-blue .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}
.egret-blue .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.egret-blue .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-checkbox-checkmark {
  fill: #fafafa;
}
.egret-blue .mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}
.egret-blue .mat-checkbox-mixedmark {
  background-color: #fafafa;
}
.egret-blue .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .egret-blue .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #247ba0;
}
.egret-blue .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .egret-blue .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #ffd97d;
}
.egret-blue .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .egret-blue .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.egret-blue .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .egret-blue .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.egret-blue .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.egret-blue .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-checkbox .mat-ripple-element {
  background-color: black;
}
.egret-blue .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.egret-blue .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #247ba0;
}
.egret-blue .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.egret-blue .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #ffd97d;
}
.egret-blue .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.egret-blue .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.egret-blue .mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.egret-blue .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.egret-blue .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.egret-blue .mat-chip.mat-standard-chip::after {
  background: black;
}
.egret-blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #247ba0;
  color: white;
}
.egret-blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.egret-blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #ffd97d;
  color: black;
}
.egret-blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.egret-blue .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.egret-blue .mat-table {
  background: white;
}
.egret-blue .mat-table thead, .egret-blue .mat-table tbody, .egret-blue .mat-table tfoot,
.egret-blue mat-header-row, .egret-blue mat-row, .egret-blue mat-footer-row,
.egret-blue [mat-header-row], .egret-blue [mat-row], .egret-blue [mat-footer-row],
.egret-blue .mat-table-sticky {
  background: inherit;
}
.egret-blue mat-row, .egret-blue mat-header-row, .egret-blue mat-footer-row,
.egret-blue th.mat-header-cell, .egret-blue td.mat-cell, .egret-blue td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-cell, .egret-blue .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-calendar-arrow {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-datepicker-toggle,
.egret-blue .mat-datepicker-content .mat-calendar-next-button,
.egret-blue .mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-calendar-table-header {
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-calendar-body-cell-content,
.egret-blue .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}
.egret-blue .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.egret-blue .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.egret-blue .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(0, 0, 0, 0.04);
}
.egret-blue .mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}
.egret-blue .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}
.egret-blue .mat-calendar-body-in-range::before {
  background: rgba(36, 123, 160, 0.2);
}
.egret-blue .mat-calendar-body-comparison-identical,
.egret-blue .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-blue .mat-calendar-body-comparison-bridge-start::before,
.egret-blue [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(36, 123, 160, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-blue .mat-calendar-body-comparison-bridge-end::before,
.egret-blue [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(36, 123, 160, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-blue .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-blue .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-blue .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-blue .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-blue .mat-calendar-body-selected {
  background-color: #247ba0;
  color: white;
}
.egret-blue .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(36, 123, 160, 0.4);
}
.egret-blue .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.egret-blue .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 217, 125, 0.2);
}
.egret-blue .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.egret-blue .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-blue .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.egret-blue .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 217, 125, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-blue .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.egret-blue .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 217, 125, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-blue .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-blue .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-blue .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-blue .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-blue .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #ffd97d;
  color: black;
}
.egret-blue .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 217, 125, 0.4);
}
.egret-blue .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.egret-blue .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.egret-blue .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.egret-blue .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-blue .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.egret-blue .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-blue .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.egret-blue .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-blue .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-blue .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-blue .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-blue .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-blue .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.egret-blue .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.egret-blue .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.egret-blue .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-datepicker-toggle-active {
  color: #247ba0;
}
.egret-blue .mat-datepicker-toggle-active.mat-accent {
  color: #ffd97d;
}
.egret-blue .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.egret-blue .mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .egret-blue .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .egret-blue .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .egret-blue .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.egret-blue .mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-expansion-panel-header-description,
.egret-blue .mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.egret-blue .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.egret-blue .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.egret-blue .mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}
.egret-blue .mat-hint {
  color: rgba(0, 0, 0, 0.6);
}
.egret-blue .mat-form-field.mat-focused .mat-form-field-label {
  color: #247ba0;
}
.egret-blue .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #ffd97d;
}
.egret-blue .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.egret-blue .mat-focused .mat-form-field-required-marker {
  color: #ffd97d;
}
.egret-blue .mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #247ba0;
}
.egret-blue .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #ffd97d;
}
.egret-blue .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.egret-blue .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #247ba0;
}
.egret-blue .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #ffd97d;
}
.egret-blue .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.egret-blue .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.egret-blue .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.egret-blue .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.egret-blue .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.egret-blue .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.egret-blue .mat-error {
  color: #f44336;
}
.egret-blue .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.egret-blue .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.egret-blue .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.egret-blue .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.egret-blue .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.egret-blue .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.egret-blue .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.egret-blue .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.egret-blue .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #247ba0;
}
.egret-blue .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #ffd97d;
}
.egret-blue .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.egret-blue .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.egret-blue .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}
.egret-blue .mat-icon.mat-primary {
  color: #247ba0;
}
.egret-blue .mat-icon.mat-accent {
  color: #ffd97d;
}
.egret-blue .mat-icon.mat-warn {
  color: #f44336;
}
.egret-blue .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-input-element:disabled,
.egret-blue .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-input-element {
  caret-color: #247ba0;
}
.egret-blue .mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.egret-blue .mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.egret-blue .mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.egret-blue .mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.egret-blue .mat-form-field.mat-accent .mat-input-element {
  caret-color: #ffd97d;
}
.egret-blue .mat-form-field.mat-warn .mat-input-element,
.egret-blue .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.egret-blue .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.egret-blue .mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-list-item-disabled {
  background-color: #eeeeee;
}
.egret-blue .mat-list-option:hover, .egret-blue .mat-list-option:focus,
.egret-blue .mat-nav-list .mat-list-item:hover,
.egret-blue .mat-nav-list .mat-list-item:focus,
.egret-blue .mat-action-list .mat-list-item:hover,
.egret-blue .mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}
.egret-blue .mat-list-single-selected-option, .egret-blue .mat-list-single-selected-option:hover, .egret-blue .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-menu-panel {
  background: white;
}
.egret-blue .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-menu-item[disabled], .egret-blue .mat-menu-item[disabled]::after {
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-menu-item .mat-icon-no-color,
.egret-blue .mat-menu-item-submenu-trigger::after {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-menu-item:hover:not([disabled]),
.egret-blue .mat-menu-item.cdk-program-focused:not([disabled]),
.egret-blue .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.egret-blue .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}
.egret-blue .mat-paginator {
  background: white;
}
.egret-blue .mat-paginator,
.egret-blue .mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-paginator-decrement,
.egret-blue .mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-paginator-first,
.egret-blue .mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-icon-button[disabled] .mat-paginator-decrement,
.egret-blue .mat-icon-button[disabled] .mat-paginator-increment,
.egret-blue .mat-icon-button[disabled] .mat-paginator-first,
.egret-blue .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-progress-bar-background {
  fill: #bdd7e3;
}
.egret-blue .mat-progress-bar-buffer {
  background-color: #bdd7e3;
}
.egret-blue .mat-progress-bar-fill::after {
  background-color: #247ba0;
}
.egret-blue .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fff4d8;
}
.egret-blue .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fff4d8;
}
.egret-blue .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #ffd97d;
}
.egret-blue .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.egret-blue .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.egret-blue .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.egret-blue .mat-progress-spinner circle, .egret-blue .mat-spinner circle {
  stroke: #247ba0;
}
.egret-blue .mat-progress-spinner.mat-accent circle, .egret-blue .mat-spinner.mat-accent circle {
  stroke: #ffd97d;
}
.egret-blue .mat-progress-spinner.mat-warn circle, .egret-blue .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.egret-blue .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #247ba0;
}
.egret-blue .mat-radio-button.mat-primary .mat-radio-inner-circle,
.egret-blue .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-blue .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .egret-blue .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #247ba0;
}
.egret-blue .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #ffd97d;
}
.egret-blue .mat-radio-button.mat-accent .mat-radio-inner-circle,
.egret-blue .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-blue .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .egret-blue .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #ffd97d;
}
.egret-blue .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.egret-blue .mat-radio-button.mat-warn .mat-radio-inner-circle,
.egret-blue .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-blue .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .egret-blue .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.egret-blue .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.egret-blue .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.egret-blue .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-radio-button .mat-ripple-element {
  background-color: black;
}
.egret-blue .mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.egret-blue .mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-select-panel {
  background: white;
}
.egret-blue .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #247ba0;
}
.egret-blue .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #ffd97d;
}
.egret-blue .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.egret-blue .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.egret-blue .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-drawer.mat-drawer-push {
  background-color: white;
}
.egret-blue .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.egret-blue [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
.egret-blue [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}
.egret-blue .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffd97d;
}
.egret-blue .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 217, 125, 0.54);
}
.egret-blue .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #ffd97d;
}
.egret-blue .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #247ba0;
}
.egret-blue .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(36, 123, 160, 0.54);
}
.egret-blue .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #247ba0;
}
.egret-blue .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.egret-blue .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.egret-blue .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.egret-blue .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}
.egret-blue .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.egret-blue .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.egret-blue .mat-primary .mat-slider-track-fill,
.egret-blue .mat-primary .mat-slider-thumb,
.egret-blue .mat-primary .mat-slider-thumb-label {
  background-color: #247ba0;
}
.egret-blue .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.egret-blue .mat-primary .mat-slider-focus-ring {
  background-color: rgba(36, 123, 160, 0.2);
}
.egret-blue .mat-accent .mat-slider-track-fill,
.egret-blue .mat-accent .mat-slider-thumb,
.egret-blue .mat-accent .mat-slider-thumb-label {
  background-color: #ffd97d;
}
.egret-blue .mat-accent .mat-slider-thumb-label-text {
  color: black;
}
.egret-blue .mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 217, 125, 0.2);
}
.egret-blue .mat-warn .mat-slider-track-fill,
.egret-blue .mat-warn .mat-slider-thumb,
.egret-blue .mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.egret-blue .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.egret-blue .mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.egret-blue .mat-slider:hover .mat-slider-track-background,
.egret-blue .cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-slider-disabled .mat-slider-track-background,
.egret-blue .mat-slider-disabled .mat-slider-track-fill,
.egret-blue .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.egret-blue .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}
.egret-blue .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.egret-blue .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.egret-blue .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.egret-blue .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.egret-blue .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .egret-blue .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .egret-blue .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}
.egret-blue .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}
.egret-blue .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.egret-blue .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}
.egret-blue .mat-step-header.cdk-keyboard-focused, .egret-blue .mat-step-header.cdk-program-focused, .egret-blue .mat-step-header:hover {
  background-color: rgba(0, 0, 0, 0.04);
}
@media (hover: none) {
  .egret-blue .mat-step-header:hover {
    background: none;
  }
}
.egret-blue .mat-step-header .mat-step-label,
.egret-blue .mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.egret-blue .mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.egret-blue .mat-step-header .mat-step-icon-selected,
.egret-blue .mat-step-header .mat-step-icon-state-done,
.egret-blue .mat-step-header .mat-step-icon-state-edit {
  background-color: #247ba0;
  color: white;
}
.egret-blue .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.egret-blue .mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.egret-blue .mat-stepper-horizontal, .egret-blue .mat-stepper-vertical {
  background-color: white;
}
.egret-blue .mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-horizontal-stepper-header::before,
.egret-blue .mat-horizontal-stepper-header::after,
.egret-blue .mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-sort-header-arrow {
  color: #757575;
}
.egret-blue .mat-tab-nav-bar,
.egret-blue .mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-tab-group-inverted-header .mat-tab-nav-bar,
.egret-blue .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}
.egret-blue .mat-tab-label, .egret-blue .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-tab-label.mat-tab-disabled, .egret-blue .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}
.egret-blue .mat-tab-group[class*=mat-background-] .mat-tab-header,
.egret-blue .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.egret-blue .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(189, 215, 227, 0.3);
}
.egret-blue .mat-tab-group.mat-primary .mat-ink-bar, .egret-blue .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #247ba0;
}
.egret-blue .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .egret-blue .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.egret-blue .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 244, 216, 0.3);
}
.egret-blue .mat-tab-group.mat-accent .mat-ink-bar, .egret-blue .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #ffd97d;
}
.egret-blue .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .egret-blue .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: black;
}
.egret-blue .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.egret-blue .mat-tab-group.mat-warn .mat-ink-bar, .egret-blue .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.egret-blue .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .egret-blue .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.egret-blue .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(189, 215, 227, 0.3);
}
.egret-blue .mat-tab-group.mat-background-primary .mat-tab-header, .egret-blue .mat-tab-group.mat-background-primary .mat-tab-links, .egret-blue .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .egret-blue .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .egret-blue .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .egret-blue .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #247ba0;
}
.egret-blue .mat-tab-group.mat-background-primary .mat-tab-label, .egret-blue .mat-tab-group.mat-background-primary .mat-tab-link, .egret-blue .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .egret-blue .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.egret-blue .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .egret-blue .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .egret-blue .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .egret-blue .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.egret-blue .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .egret-blue .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.egret-blue .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-blue .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.egret-blue .mat-tab-group.mat-background-primary .mat-ripple-element, .egret-blue .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-blue .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 244, 216, 0.3);
}
.egret-blue .mat-tab-group.mat-background-accent .mat-tab-header, .egret-blue .mat-tab-group.mat-background-accent .mat-tab-links, .egret-blue .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .egret-blue .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .egret-blue .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .egret-blue .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #ffd97d;
}
.egret-blue .mat-tab-group.mat-background-accent .mat-tab-label, .egret-blue .mat-tab-group.mat-background-accent .mat-tab-link, .egret-blue .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .egret-blue .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: black;
}
.egret-blue .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .egret-blue .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .egret-blue .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .egret-blue .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.egret-blue .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .egret-blue .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: black;
}
.egret-blue .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-blue .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.egret-blue .mat-tab-group.mat-background-accent .mat-ripple-element, .egret-blue .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-blue .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-blue .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.egret-blue .mat-tab-group.mat-background-warn .mat-tab-header, .egret-blue .mat-tab-group.mat-background-warn .mat-tab-links, .egret-blue .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .egret-blue .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .egret-blue .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .egret-blue .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #f44336;
}
.egret-blue .mat-tab-group.mat-background-warn .mat-tab-label, .egret-blue .mat-tab-group.mat-background-warn .mat-tab-link, .egret-blue .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .egret-blue .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.egret-blue .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .egret-blue .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .egret-blue .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .egret-blue .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.egret-blue .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .egret-blue .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.egret-blue .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-blue .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.egret-blue .mat-tab-group.mat-background-warn .mat-ripple-element, .egret-blue .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-blue .mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-toolbar.mat-primary {
  background: #247ba0;
  color: white;
}
.egret-blue .mat-toolbar.mat-accent {
  background: #ffd97d;
  color: black;
}
.egret-blue .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.egret-blue .mat-toolbar .mat-form-field-underline,
.egret-blue .mat-toolbar .mat-form-field-ripple,
.egret-blue .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.egret-blue .mat-toolbar .mat-form-field-label,
.egret-blue .mat-toolbar .mat-focused .mat-form-field-label,
.egret-blue .mat-toolbar .mat-select-value,
.egret-blue .mat-toolbar .mat-select-arrow,
.egret-blue .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.egret-blue .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.egret-blue .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.egret-blue .mat-tree {
  background: white;
}
.egret-blue .mat-tree-node,
.egret-blue .mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}
.egret-blue .mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-blue .mat-simple-snackbar-action {
  color: #ffd97d;
}
.egret-blue .mat-bg-primary,
.egret-blue .topbar,
.egret-blue .chats-wrap .conversations-hold .single-conversation.me .conversation-msg,
.egret-blue .ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a,
.egret-blue .fileupload-drop-zone.dz-file-over,
.egret-blue .toolbar-avatar.online > .status-dot,
.egret-blue .cal-open-day-events,
.egret-blue div.hopscotch-bubble {
  background: #247ba0 !important;
  color: #ffffff !important;
}
.egret-blue .mat-color-primary,
.egret-blue .list-item-active,
.egret-blue .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.egret-blue .sidenav-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.egret-blue .sidenav-hold .sidenav li.open > a > span {
  color: #247ba0 !important;
}
.egret-blue .topnav ul.menu > li > div.open > a,
.egret-blue .topnav ul.menu > li > div.open > div,
.egret-blue .sidebar-panel .sidebar-list-item.open > .mat-list-item-content > .sub-menu,
.egret-blue .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.egret-blue .list-item-active {
  border-color: #247ba0 !important;
}
.egret-blue .sidebar-compact ul.submenu,
.egret-blue .default-bg {
  background: #ffffff !important;
}
.egret-blue div.hopscotch-bubble {
  border-color: #1d617f;
}
.egret-blue div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom: 17px solid #1d617f;
}
.egret-blue div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
  border-bottom: 17px solid #247ba0;
  top: -16px;
}
.egret-blue div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left: 17px solid #1d617f;
}
.egret-blue div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
  border-left: 17px solid #247ba0;
  left: -1px;
}
.egret-blue div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top: 17px solid #1d617f;
}
.egret-blue div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
  border-top: 17px solid #247ba0;
  top: -18px;
}
.egret-blue div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
  border-right: 17px solid #1d617f;
}
.egret-blue div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
  left: 1px;
  border-right: 17px solid #247ba0;
}
.egret-blue .mat-bg-accent,
.egret-blue div.hopscotch-bubble .hopscotch-bubble-number,
.egret-blue div.hopscotch-bubble .hopscotch-nav-button.next:hover,
.egret-blue div.hopscotch-bubble .hopscotch-nav-button.next {
  background-color: #ffd97d;
  color: black;
}
.egret-blue .mat-bg-warn {
  background-color: #f44336;
  color: white;
}
.egret-blue .mat-color-accent {
  color: #ffd97d;
}
.egret-blue .mat-color-warn {
  color: #f44336;
}
.egret-blue .mat-color-default {
  color: rgba(0, 0, 0, 0.87);
}

.egret-dark-purple {
  /* Hopscotch Tour */
  /* up arrow  */
  /* right arrow */
  /* bottom arrow */
  /* Left Arrow */
}
.egret-dark-purple .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-dark-purple .mat-option {
  color: white;
}
.egret-dark-purple .mat-option:hover:not(.mat-option-disabled), .egret-dark-purple .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.egret-dark-purple .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.egret-dark-purple .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.egret-dark-purple .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #9c27b0;
}
.egret-dark-purple .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #4caf50;
}
.egret-dark-purple .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.egret-dark-purple .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-pseudo-checkbox::after {
  color: #303030;
}
.egret-dark-purple .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.egret-dark-purple .mat-primary .mat-pseudo-checkbox-checked,
.egret-dark-purple .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #9c27b0;
}
.egret-dark-purple .mat-pseudo-checkbox-checked,
.egret-dark-purple .mat-pseudo-checkbox-indeterminate,
.egret-dark-purple .mat-accent .mat-pseudo-checkbox-checked,
.egret-dark-purple .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #4caf50;
}
.egret-dark-purple .mat-warn .mat-pseudo-checkbox-checked,
.egret-dark-purple .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.egret-dark-purple .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.egret-dark-purple .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.egret-dark-purple .mat-app-background, .egret-dark-purple.mat-app-background {
  background-color: #303030;
  color: white;
}
.egret-dark-purple .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.egret-dark-purple .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.egret-dark-purple .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.egret-dark-purple .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.egret-dark-purple .mat-badge-content {
  color: white;
  background: #9c27b0;
}
.cdk-high-contrast-active .egret-dark-purple .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.egret-dark-purple .mat-badge-accent .mat-badge-content {
  background: #4caf50;
  color: black;
}
.egret-dark-purple .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.egret-dark-purple .mat-badge {
  position: relative;
}
.egret-dark-purple .mat-badge-hidden .mat-badge-content {
  display: none;
}
.egret-dark-purple .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.egret-dark-purple .ng-animate-disabled .mat-badge-content,
.egret-dark-purple .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.egret-dark-purple .mat-badge-content.mat-badge-active {
  transform: none;
}
.egret-dark-purple .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.egret-dark-purple .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.egret-dark-purple .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.egret-dark-purple .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .egret-dark-purple .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.egret-dark-purple .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .egret-dark-purple .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.egret-dark-purple .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .egret-dark-purple .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.egret-dark-purple .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .egret-dark-purple .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.egret-dark-purple .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.egret-dark-purple .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.egret-dark-purple .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.egret-dark-purple .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .egret-dark-purple .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.egret-dark-purple .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .egret-dark-purple .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.egret-dark-purple .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .egret-dark-purple .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.egret-dark-purple .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .egret-dark-purple .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.egret-dark-purple .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.egret-dark-purple .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.egret-dark-purple .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.egret-dark-purple .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .egret-dark-purple .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.egret-dark-purple .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .egret-dark-purple .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.egret-dark-purple .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .egret-dark-purple .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.egret-dark-purple .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .egret-dark-purple .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.egret-dark-purple .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.egret-dark-purple .mat-button, .egret-dark-purple .mat-icon-button, .egret-dark-purple .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.egret-dark-purple .mat-button.mat-primary, .egret-dark-purple .mat-icon-button.mat-primary, .egret-dark-purple .mat-stroked-button.mat-primary {
  color: #9c27b0;
}
.egret-dark-purple .mat-button.mat-accent, .egret-dark-purple .mat-icon-button.mat-accent, .egret-dark-purple .mat-stroked-button.mat-accent {
  color: #4caf50;
}
.egret-dark-purple .mat-button.mat-warn, .egret-dark-purple .mat-icon-button.mat-warn, .egret-dark-purple .mat-stroked-button.mat-warn {
  color: #f44336;
}
.egret-dark-purple .mat-button.mat-primary.mat-button-disabled, .egret-dark-purple .mat-button.mat-accent.mat-button-disabled, .egret-dark-purple .mat-button.mat-warn.mat-button-disabled, .egret-dark-purple .mat-button.mat-button-disabled.mat-button-disabled, .egret-dark-purple .mat-icon-button.mat-primary.mat-button-disabled, .egret-dark-purple .mat-icon-button.mat-accent.mat-button-disabled, .egret-dark-purple .mat-icon-button.mat-warn.mat-button-disabled, .egret-dark-purple .mat-icon-button.mat-button-disabled.mat-button-disabled, .egret-dark-purple .mat-stroked-button.mat-primary.mat-button-disabled, .egret-dark-purple .mat-stroked-button.mat-accent.mat-button-disabled, .egret-dark-purple .mat-stroked-button.mat-warn.mat-button-disabled, .egret-dark-purple .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.egret-dark-purple .mat-button.mat-primary .mat-button-focus-overlay, .egret-dark-purple .mat-icon-button.mat-primary .mat-button-focus-overlay, .egret-dark-purple .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #9c27b0;
}
.egret-dark-purple .mat-button.mat-accent .mat-button-focus-overlay, .egret-dark-purple .mat-icon-button.mat-accent .mat-button-focus-overlay, .egret-dark-purple .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #4caf50;
}
.egret-dark-purple .mat-button.mat-warn .mat-button-focus-overlay, .egret-dark-purple .mat-icon-button.mat-warn .mat-button-focus-overlay, .egret-dark-purple .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.egret-dark-purple .mat-button.mat-button-disabled .mat-button-focus-overlay, .egret-dark-purple .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .egret-dark-purple .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.egret-dark-purple .mat-button .mat-ripple-element, .egret-dark-purple .mat-icon-button .mat-ripple-element, .egret-dark-purple .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.egret-dark-purple .mat-button-focus-overlay {
  background: white;
}
.egret-dark-purple .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-flat-button, .egret-dark-purple .mat-raised-button, .egret-dark-purple .mat-fab, .egret-dark-purple .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.egret-dark-purple .mat-flat-button.mat-primary, .egret-dark-purple .mat-raised-button.mat-primary, .egret-dark-purple .mat-fab.mat-primary, .egret-dark-purple .mat-mini-fab.mat-primary {
  color: white;
}
.egret-dark-purple .mat-flat-button.mat-accent, .egret-dark-purple .mat-raised-button.mat-accent, .egret-dark-purple .mat-fab.mat-accent, .egret-dark-purple .mat-mini-fab.mat-accent {
  color: black;
}
.egret-dark-purple .mat-flat-button.mat-warn, .egret-dark-purple .mat-raised-button.mat-warn, .egret-dark-purple .mat-fab.mat-warn, .egret-dark-purple .mat-mini-fab.mat-warn {
  color: white;
}
.egret-dark-purple .mat-flat-button.mat-primary.mat-button-disabled, .egret-dark-purple .mat-flat-button.mat-accent.mat-button-disabled, .egret-dark-purple .mat-flat-button.mat-warn.mat-button-disabled, .egret-dark-purple .mat-flat-button.mat-button-disabled.mat-button-disabled, .egret-dark-purple .mat-raised-button.mat-primary.mat-button-disabled, .egret-dark-purple .mat-raised-button.mat-accent.mat-button-disabled, .egret-dark-purple .mat-raised-button.mat-warn.mat-button-disabled, .egret-dark-purple .mat-raised-button.mat-button-disabled.mat-button-disabled, .egret-dark-purple .mat-fab.mat-primary.mat-button-disabled, .egret-dark-purple .mat-fab.mat-accent.mat-button-disabled, .egret-dark-purple .mat-fab.mat-warn.mat-button-disabled, .egret-dark-purple .mat-fab.mat-button-disabled.mat-button-disabled, .egret-dark-purple .mat-mini-fab.mat-primary.mat-button-disabled, .egret-dark-purple .mat-mini-fab.mat-accent.mat-button-disabled, .egret-dark-purple .mat-mini-fab.mat-warn.mat-button-disabled, .egret-dark-purple .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.egret-dark-purple .mat-flat-button.mat-primary, .egret-dark-purple .mat-raised-button.mat-primary, .egret-dark-purple .mat-fab.mat-primary, .egret-dark-purple .mat-mini-fab.mat-primary {
  background-color: #9c27b0;
}
.egret-dark-purple .mat-flat-button.mat-accent, .egret-dark-purple .mat-raised-button.mat-accent, .egret-dark-purple .mat-fab.mat-accent, .egret-dark-purple .mat-mini-fab.mat-accent {
  background-color: #4caf50;
}
.egret-dark-purple .mat-flat-button.mat-warn, .egret-dark-purple .mat-raised-button.mat-warn, .egret-dark-purple .mat-fab.mat-warn, .egret-dark-purple .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.egret-dark-purple .mat-flat-button.mat-primary.mat-button-disabled, .egret-dark-purple .mat-flat-button.mat-accent.mat-button-disabled, .egret-dark-purple .mat-flat-button.mat-warn.mat-button-disabled, .egret-dark-purple .mat-flat-button.mat-button-disabled.mat-button-disabled, .egret-dark-purple .mat-raised-button.mat-primary.mat-button-disabled, .egret-dark-purple .mat-raised-button.mat-accent.mat-button-disabled, .egret-dark-purple .mat-raised-button.mat-warn.mat-button-disabled, .egret-dark-purple .mat-raised-button.mat-button-disabled.mat-button-disabled, .egret-dark-purple .mat-fab.mat-primary.mat-button-disabled, .egret-dark-purple .mat-fab.mat-accent.mat-button-disabled, .egret-dark-purple .mat-fab.mat-warn.mat-button-disabled, .egret-dark-purple .mat-fab.mat-button-disabled.mat-button-disabled, .egret-dark-purple .mat-mini-fab.mat-primary.mat-button-disabled, .egret-dark-purple .mat-mini-fab.mat-accent.mat-button-disabled, .egret-dark-purple .mat-mini-fab.mat-warn.mat-button-disabled, .egret-dark-purple .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-flat-button.mat-primary .mat-ripple-element, .egret-dark-purple .mat-raised-button.mat-primary .mat-ripple-element, .egret-dark-purple .mat-fab.mat-primary .mat-ripple-element, .egret-dark-purple .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-dark-purple .mat-flat-button.mat-accent .mat-ripple-element, .egret-dark-purple .mat-raised-button.mat-accent .mat-ripple-element, .egret-dark-purple .mat-fab.mat-accent .mat-ripple-element, .egret-dark-purple .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.egret-dark-purple .mat-flat-button.mat-warn .mat-ripple-element, .egret-dark-purple .mat-raised-button.mat-warn .mat-ripple-element, .egret-dark-purple .mat-fab.mat-warn .mat-ripple-element, .egret-dark-purple .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-dark-purple .mat-stroked-button:not([class*=mat-elevation-z]), .egret-dark-purple .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-fab:not([class*=mat-elevation-z]), .egret-dark-purple .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .egret-dark-purple .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .egret-dark-purple .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-button-toggle-standalone,
.egret-dark-purple .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.egret-dark-purple .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.egret-dark-purple .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.egret-dark-purple .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.egret-dark-purple .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.egret-dark-purple [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.egret-dark-purple .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.egret-dark-purple .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.egret-dark-purple .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.egret-dark-purple .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.egret-dark-purple .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-card {
  background: #424242;
  color: white;
}
.egret-dark-purple .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-checkbox-checkmark {
  fill: #303030;
}
.egret-dark-purple .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.egret-dark-purple .mat-checkbox-mixedmark {
  background-color: #303030;
}
.egret-dark-purple .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .egret-dark-purple .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #9c27b0;
}
.egret-dark-purple .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .egret-dark-purple .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #4caf50;
}
.egret-dark-purple .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .egret-dark-purple .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.egret-dark-purple .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .egret-dark-purple .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.egret-dark-purple .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.egret-dark-purple .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.egret-dark-purple .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.egret-dark-purple .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #9c27b0;
}
.egret-dark-purple .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.egret-dark-purple .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #4caf50;
}
.egret-dark-purple .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.egret-dark-purple .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.egret-dark-purple .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.egret-dark-purple .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-dark-purple .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.egret-dark-purple .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.egret-dark-purple .mat-chip.mat-standard-chip::after {
  background: white;
}
.egret-dark-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #9c27b0;
  color: white;
}
.egret-dark-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-dark-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-dark-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.egret-dark-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-dark-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-dark-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #4caf50;
  color: black;
}
.egret-dark-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: black;
  opacity: 0.4;
}
.egret-dark-purple .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.egret-dark-purple .mat-table {
  background: #424242;
}
.egret-dark-purple .mat-table thead, .egret-dark-purple .mat-table tbody, .egret-dark-purple .mat-table tfoot,
.egret-dark-purple mat-header-row, .egret-dark-purple mat-row, .egret-dark-purple mat-footer-row,
.egret-dark-purple [mat-header-row], .egret-dark-purple [mat-row], .egret-dark-purple [mat-footer-row],
.egret-dark-purple .mat-table-sticky {
  background: inherit;
}
.egret-dark-purple mat-row, .egret-dark-purple mat-header-row, .egret-dark-purple mat-footer-row,
.egret-dark-purple th.mat-header-cell, .egret-dark-purple td.mat-cell, .egret-dark-purple td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-cell, .egret-dark-purple .mat-footer-cell {
  color: white;
}
.egret-dark-purple .mat-calendar-arrow {
  border-top-color: white;
}
.egret-dark-purple .mat-datepicker-toggle,
.egret-dark-purple .mat-datepicker-content .mat-calendar-next-button,
.egret-dark-purple .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.egret-dark-purple .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-calendar-body-cell-content,
.egret-dark-purple .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.egret-dark-purple .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.egret-dark-purple .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.egret-dark-purple .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 255, 255, 0.04);
}
.egret-dark-purple .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.egret-dark-purple .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-purple .mat-calendar-body-in-range::before {
  background: rgba(156, 39, 176, 0.2);
}
.egret-dark-purple .mat-calendar-body-comparison-identical,
.egret-dark-purple .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-dark-purple .mat-calendar-body-comparison-bridge-start::before,
.egret-dark-purple [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(156, 39, 176, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-dark-purple .mat-calendar-body-comparison-bridge-end::before,
.egret-dark-purple [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(156, 39, 176, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-dark-purple .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-dark-purple .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-dark-purple .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-dark-purple .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-dark-purple .mat-calendar-body-selected {
  background-color: #9c27b0;
  color: white;
}
.egret-dark-purple .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(156, 39, 176, 0.4);
}
.egret-dark-purple .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.egret-dark-purple .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.egret-dark-purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(76, 175, 80, 0.2);
}
.egret-dark-purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.egret-dark-purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-dark-purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.egret-dark-purple .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(76, 175, 80, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-dark-purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.egret-dark-purple .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(76, 175, 80, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-dark-purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-dark-purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-dark-purple .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-dark-purple .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-dark-purple .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #4caf50;
  color: black;
}
.egret-dark-purple .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(76, 175, 80, 0.4);
}
.egret-dark-purple .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px black;
}
.egret-dark-purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.egret-dark-purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.egret-dark-purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-dark-purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.egret-dark-purple .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-dark-purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.egret-dark-purple .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-dark-purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-dark-purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-dark-purple .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-dark-purple .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-dark-purple .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.egret-dark-purple .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.egret-dark-purple .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.egret-dark-purple .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-datepicker-toggle-active {
  color: #9c27b0;
}
.egret-dark-purple .mat-datepicker-toggle-active.mat-accent {
  color: #4caf50;
}
.egret-dark-purple .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.egret-dark-purple .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.egret-dark-purple .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-expansion-panel {
  background: #424242;
  color: white;
}
.egret-dark-purple .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .egret-dark-purple .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .egret-dark-purple .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .egret-dark-purple .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.egret-dark-purple .mat-expansion-panel-header-title {
  color: white;
}
.egret-dark-purple .mat-expansion-panel-header-description,
.egret-dark-purple .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.egret-dark-purple .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.egret-dark-purple .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.egret-dark-purple .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-form-field.mat-focused .mat-form-field-label {
  color: #9c27b0;
}
.egret-dark-purple .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #4caf50;
}
.egret-dark-purple .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.egret-dark-purple .mat-focused .mat-form-field-required-marker {
  color: #4caf50;
}
.egret-dark-purple .mat-form-field-ripple {
  background-color: white;
}
.egret-dark-purple .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #9c27b0;
}
.egret-dark-purple .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #4caf50;
}
.egret-dark-purple .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.egret-dark-purple .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #9c27b0;
}
.egret-dark-purple .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #4caf50;
}
.egret-dark-purple .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.egret-dark-purple .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.egret-dark-purple .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.egret-dark-purple .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.egret-dark-purple .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.egret-dark-purple .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.egret-dark-purple .mat-error {
  color: #f44336;
}
.egret-dark-purple .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.egret-dark-purple .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.egret-dark-purple .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-dark-purple .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.egret-dark-purple .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.egret-dark-purple .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.egret-dark-purple .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.egret-dark-purple .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #9c27b0;
}
.egret-dark-purple .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #4caf50;
}
.egret-dark-purple .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.egret-dark-purple .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.egret-dark-purple .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.egret-dark-purple .mat-icon.mat-primary {
  color: #9c27b0;
}
.egret-dark-purple .mat-icon.mat-accent {
  color: #4caf50;
}
.egret-dark-purple .mat-icon.mat-warn {
  color: #f44336;
}
.egret-dark-purple .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-input-element:disabled,
.egret-dark-purple .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-input-element {
  caret-color: #9c27b0;
}
.egret-dark-purple .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-input-element option {
  color: rgba(0, 0, 0, 0.87);
}
.egret-dark-purple .mat-input-element option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.egret-dark-purple .mat-form-field.mat-accent .mat-input-element {
  caret-color: #4caf50;
}
.egret-dark-purple .mat-form-field.mat-warn .mat-input-element,
.egret-dark-purple .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.egret-dark-purple .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.egret-dark-purple .mat-list-base .mat-list-item {
  color: white;
}
.egret-dark-purple .mat-list-base .mat-list-option {
  color: white;
}
.egret-dark-purple .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-list-item-disabled {
  background-color: black;
}
.egret-dark-purple .mat-list-option:hover, .egret-dark-purple .mat-list-option:focus,
.egret-dark-purple .mat-nav-list .mat-list-item:hover,
.egret-dark-purple .mat-nav-list .mat-list-item:focus,
.egret-dark-purple .mat-action-list .mat-list-item:hover,
.egret-dark-purple .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.egret-dark-purple .mat-list-single-selected-option, .egret-dark-purple .mat-list-single-selected-option:hover, .egret-dark-purple .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-menu-panel {
  background: #424242;
}
.egret-dark-purple .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-menu-item {
  background: transparent;
  color: white;
}
.egret-dark-purple .mat-menu-item[disabled], .egret-dark-purple .mat-menu-item[disabled]::after {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-menu-item .mat-icon-no-color,
.egret-dark-purple .mat-menu-item-submenu-trigger::after {
  color: white;
}
.egret-dark-purple .mat-menu-item:hover:not([disabled]),
.egret-dark-purple .mat-menu-item.cdk-program-focused:not([disabled]),
.egret-dark-purple .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.egret-dark-purple .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.egret-dark-purple .mat-paginator {
  background: #424242;
}
.egret-dark-purple .mat-paginator,
.egret-dark-purple .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-paginator-decrement,
.egret-dark-purple .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.egret-dark-purple .mat-paginator-first,
.egret-dark-purple .mat-paginator-last {
  border-top: 2px solid white;
}
.egret-dark-purple .mat-icon-button[disabled] .mat-paginator-decrement,
.egret-dark-purple .mat-icon-button[disabled] .mat-paginator-increment,
.egret-dark-purple .mat-icon-button[disabled] .mat-paginator-first,
.egret-dark-purple .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-progress-bar-background {
  fill: #e1bee7;
}
.egret-dark-purple .mat-progress-bar-buffer {
  background-color: #e1bee7;
}
.egret-dark-purple .mat-progress-bar-fill::after {
  background-color: #9c27b0;
}
.egret-dark-purple .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #c9e7cb;
}
.egret-dark-purple .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #c9e7cb;
}
.egret-dark-purple .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #4caf50;
}
.egret-dark-purple .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.egret-dark-purple .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.egret-dark-purple .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.egret-dark-purple .mat-progress-spinner circle, .egret-dark-purple .mat-spinner circle {
  stroke: #9c27b0;
}
.egret-dark-purple .mat-progress-spinner.mat-accent circle, .egret-dark-purple .mat-spinner.mat-accent circle {
  stroke: #4caf50;
}
.egret-dark-purple .mat-progress-spinner.mat-warn circle, .egret-dark-purple .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.egret-dark-purple .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #9c27b0;
}
.egret-dark-purple .mat-radio-button.mat-primary .mat-radio-inner-circle,
.egret-dark-purple .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-dark-purple .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .egret-dark-purple .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #9c27b0;
}
.egret-dark-purple .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #4caf50;
}
.egret-dark-purple .mat-radio-button.mat-accent .mat-radio-inner-circle,
.egret-dark-purple .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-dark-purple .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .egret-dark-purple .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #4caf50;
}
.egret-dark-purple .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.egret-dark-purple .mat-radio-button.mat-warn .mat-radio-inner-circle,
.egret-dark-purple .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-dark-purple .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .egret-dark-purple .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.egret-dark-purple .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.egret-dark-purple .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.egret-dark-purple .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.egret-dark-purple .mat-select-value {
  color: white;
}
.egret-dark-purple .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-select-panel {
  background: #424242;
}
.egret-dark-purple .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #9c27b0;
}
.egret-dark-purple .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #4caf50;
}
.egret-dark-purple .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.egret-dark-purple .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.egret-dark-purple .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.egret-dark-purple .mat-drawer {
  background-color: #424242;
  color: white;
}
.egret-dark-purple .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.egret-dark-purple .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.egret-dark-purple [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.egret-dark-purple [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.egret-dark-purple .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #4caf50;
}
.egret-dark-purple .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(76, 175, 80, 0.54);
}
.egret-dark-purple .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #4caf50;
}
.egret-dark-purple .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #9c27b0;
}
.egret-dark-purple .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(156, 39, 176, 0.54);
}
.egret-dark-purple .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #9c27b0;
}
.egret-dark-purple .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.egret-dark-purple .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.egret-dark-purple .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.egret-dark-purple .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.egret-dark-purple .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.egret-dark-purple .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-purple .mat-primary .mat-slider-track-fill,
.egret-dark-purple .mat-primary .mat-slider-thumb,
.egret-dark-purple .mat-primary .mat-slider-thumb-label {
  background-color: #9c27b0;
}
.egret-dark-purple .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.egret-dark-purple .mat-primary .mat-slider-focus-ring {
  background-color: rgba(156, 39, 176, 0.2);
}
.egret-dark-purple .mat-accent .mat-slider-track-fill,
.egret-dark-purple .mat-accent .mat-slider-thumb,
.egret-dark-purple .mat-accent .mat-slider-thumb-label {
  background-color: #4caf50;
}
.egret-dark-purple .mat-accent .mat-slider-thumb-label-text {
  color: black;
}
.egret-dark-purple .mat-accent .mat-slider-focus-ring {
  background-color: rgba(76, 175, 80, 0.2);
}
.egret-dark-purple .mat-warn .mat-slider-track-fill,
.egret-dark-purple .mat-warn .mat-slider-thumb,
.egret-dark-purple .mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.egret-dark-purple .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.egret-dark-purple .mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.egret-dark-purple .mat-slider:hover .mat-slider-track-background,
.egret-dark-purple .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-purple .mat-slider-disabled .mat-slider-track-background,
.egret-dark-purple .mat-slider-disabled .mat-slider-track-fill,
.egret-dark-purple .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-purple .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-purple .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.egret-dark-purple .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.egret-dark-purple .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.egret-dark-purple .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-purple .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.egret-dark-purple .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .egret-dark-purple .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-purple .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .egret-dark-purple .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-purple .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.egret-dark-purple .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.egret-dark-purple .mat-step-header.cdk-keyboard-focused, .egret-dark-purple .mat-step-header.cdk-program-focused, .egret-dark-purple .mat-step-header:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .egret-dark-purple .mat-step-header:hover {
    background: none;
  }
}
.egret-dark-purple .mat-step-header .mat-step-label,
.egret-dark-purple .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-purple .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.egret-dark-purple .mat-step-header .mat-step-icon-selected,
.egret-dark-purple .mat-step-header .mat-step-icon-state-done,
.egret-dark-purple .mat-step-header .mat-step-icon-state-edit {
  background-color: #9c27b0;
  color: white;
}
.egret-dark-purple .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.egret-dark-purple .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.egret-dark-purple .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.egret-dark-purple .mat-stepper-horizontal, .egret-dark-purple .mat-stepper-vertical {
  background-color: #424242;
}
.egret-dark-purple .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-horizontal-stepper-header::before,
.egret-dark-purple .mat-horizontal-stepper-header::after,
.egret-dark-purple .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-sort-header-arrow {
  color: #c6c6c6;
}
.egret-dark-purple .mat-tab-nav-bar,
.egret-dark-purple .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-tab-group-inverted-header .mat-tab-nav-bar,
.egret-dark-purple .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.egret-dark-purple .mat-tab-label, .egret-dark-purple .mat-tab-link {
  color: white;
}
.egret-dark-purple .mat-tab-label.mat-tab-disabled, .egret-dark-purple .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-tab-header-pagination-chevron {
  border-color: white;
}
.egret-dark-purple .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.egret-dark-purple .mat-tab-group[class*=mat-background-] .mat-tab-header,
.egret-dark-purple .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.egret-dark-purple .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 190, 231, 0.3);
}
.egret-dark-purple .mat-tab-group.mat-primary .mat-ink-bar, .egret-dark-purple .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #9c27b0;
}
.egret-dark-purple .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .egret-dark-purple .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.egret-dark-purple .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(201, 231, 203, 0.3);
}
.egret-dark-purple .mat-tab-group.mat-accent .mat-ink-bar, .egret-dark-purple .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #4caf50;
}
.egret-dark-purple .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .egret-dark-purple .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: black;
}
.egret-dark-purple .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.egret-dark-purple .mat-tab-group.mat-warn .mat-ink-bar, .egret-dark-purple .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.egret-dark-purple .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .egret-dark-purple .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.egret-dark-purple .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(225, 190, 231, 0.3);
}
.egret-dark-purple .mat-tab-group.mat-background-primary .mat-tab-header, .egret-dark-purple .mat-tab-group.mat-background-primary .mat-tab-links, .egret-dark-purple .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .egret-dark-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .egret-dark-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .egret-dark-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #9c27b0;
}
.egret-dark-purple .mat-tab-group.mat-background-primary .mat-tab-label, .egret-dark-purple .mat-tab-group.mat-background-primary .mat-tab-link, .egret-dark-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .egret-dark-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.egret-dark-purple .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .egret-dark-purple .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .egret-dark-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .egret-dark-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.egret-dark-purple .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .egret-dark-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.egret-dark-purple .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-dark-purple .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.egret-dark-purple .mat-tab-group.mat-background-primary .mat-ripple-element, .egret-dark-purple .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(201, 231, 203, 0.3);
}
.egret-dark-purple .mat-tab-group.mat-background-accent .mat-tab-header, .egret-dark-purple .mat-tab-group.mat-background-accent .mat-tab-links, .egret-dark-purple .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .egret-dark-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .egret-dark-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .egret-dark-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #4caf50;
}
.egret-dark-purple .mat-tab-group.mat-background-accent .mat-tab-label, .egret-dark-purple .mat-tab-group.mat-background-accent .mat-tab-link, .egret-dark-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .egret-dark-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: black;
}
.egret-dark-purple .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .egret-dark-purple .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .egret-dark-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .egret-dark-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.egret-dark-purple .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .egret-dark-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: black;
}
.egret-dark-purple .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-dark-purple .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.4);
}
.egret-dark-purple .mat-tab-group.mat-background-accent .mat-ripple-element, .egret-dark-purple .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.egret-dark-purple .mat-tab-group.mat-background-warn .mat-tab-header, .egret-dark-purple .mat-tab-group.mat-background-warn .mat-tab-links, .egret-dark-purple .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .egret-dark-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .egret-dark-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .egret-dark-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #f44336;
}
.egret-dark-purple .mat-tab-group.mat-background-warn .mat-tab-label, .egret-dark-purple .mat-tab-group.mat-background-warn .mat-tab-link, .egret-dark-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .egret-dark-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.egret-dark-purple .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .egret-dark-purple .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .egret-dark-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .egret-dark-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.egret-dark-purple .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .egret-dark-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.egret-dark-purple .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-dark-purple .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.egret-dark-purple .mat-tab-group.mat-background-warn .mat-ripple-element, .egret-dark-purple .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-purple .mat-toolbar {
  background: #212121;
  color: white;
}
.egret-dark-purple .mat-toolbar.mat-primary {
  background: #9c27b0;
  color: white;
}
.egret-dark-purple .mat-toolbar.mat-accent {
  background: #4caf50;
  color: black;
}
.egret-dark-purple .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.egret-dark-purple .mat-toolbar .mat-form-field-underline,
.egret-dark-purple .mat-toolbar .mat-form-field-ripple,
.egret-dark-purple .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.egret-dark-purple .mat-toolbar .mat-form-field-label,
.egret-dark-purple .mat-toolbar .mat-focused .mat-form-field-label,
.egret-dark-purple .mat-toolbar .mat-select-value,
.egret-dark-purple .mat-toolbar .mat-select-arrow,
.egret-dark-purple .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.egret-dark-purple .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.egret-dark-purple .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.egret-dark-purple .mat-tree {
  background: #424242;
}
.egret-dark-purple .mat-tree-node,
.egret-dark-purple .mat-nested-tree-node {
  color: white;
}
.egret-dark-purple .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-purple .mat-simple-snackbar-action {
  color: inherit;
}
.egret-dark-purple .mat-bg-primary,
.egret-dark-purple .topbar,
.egret-dark-purple .chats-wrap .conversations-hold .single-conversation.me .conversation-msg,
.egret-dark-purple .ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a,
.egret-dark-purple .fileupload-drop-zone.dz-file-over,
.egret-dark-purple .toolbar-avatar.online > .status-dot,
.egret-dark-purple .cal-open-day-events,
.egret-dark-purple div.hopscotch-bubble {
  background: #9c27b0 !important;
  color: #ffffff !important;
}
.egret-dark-purple .mat-color-primary,
.egret-dark-purple .list-item-active,
.egret-dark-purple .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.egret-dark-purple .sidenav-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.egret-dark-purple .sidenav-hold .sidenav li.open > a > span {
  color: #9c27b0 !important;
}
.egret-dark-purple .sidebar-panel .sidebar-list-item.open > .mat-list-item-content > a > span,
.egret-dark-purple .sidebar-panel .sidebar-list-item.open .sub-menu .mat-list-item.selected a {
  font-weight: 500;
}
.egret-dark-purple .topnav ul.menu > li > div.open > a,
.egret-dark-purple .sidebar-panel .sidebar-list-item.open > .mat-list-item-content > .sub-menu,
.egret-dark-purple .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.egret-dark-purple .list-item-active {
  border-color: #9c27b0 !important;
}
.egret-dark-purple .ngx-datatable.material,
.egret-dark-purple .messages-wrap .inbox-hold {
  background: transparent !important;
}
.egret-dark-purple .header-topnav,
.egret-dark-purple .topnav ul ul,
.egret-dark-purple .app-user .app-user-name,
.egret-dark-purple .sidebar-panel .sidebar-list-item mat-icon:not(.menu-caret),
.egret-dark-purple .ngx-datatable.material *,
.egret-dark-purple .ql-snow .ql-picker,
.egret-dark-purple .mat-option,
.egret-dark-purple .mat-dialog-container,
.egret-dark-purple .ngx-pagination a, .egret-dark-purple .ngx-pagination button {
  color: white !important;
}
.egret-dark-purple .text-muted {
  color: rgba(255, 255, 255, 0.54) !important;
}
.egret-dark-purple .ql-snow .ql-stroke {
  stroke: #ffffff;
}
.egret-dark-purple .ql-snow .ql-fill {
  fill: #ffffff;
}
.egret-dark-purple .ql-toolbar.ql-snow,
.egret-dark-purple .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-color: rgba(255, 255, 255, 0.12) !important;
}
.egret-dark-purple .sidebar-panel .navigation-hold,
.egret-dark-purple .message-item.open .message-head,
.egret-dark-purple .ngx-datatable.material .datatable-header,
.egret-dark-purple .bg-white,
.egret-dark-purple .cal-month-view .cal-cell-row:hover,
.egret-dark-purple .chats-wrap .conversations-hold .single-conversation.sender .conversation-msg,
.egret-dark-purple .cal-day-view .cal-hour:nth-child(odd), .egret-dark-purple.collapsed-menu .sidebar-panel .sidebar-list-item.open .sub-menu,
.egret-dark-purple .ngx-pagination a:hover, .egret-dark-purple .ngx-pagination button:hover,
.egret-dark-purple .product-container .products-pagination .ngx-pagination .current {
  background: rgba(66, 66, 66, 0.92) !important;
}
.egret-dark-purple .sidebar-compact ul.submenu,
.egret-dark-purple .topnav ul li ul li:hover,
.egret-dark-purple .topnav ul li ul li.open,
.egret-dark-purple .default-bg {
  background: #424242;
  color: #ffffff;
}
.egret-dark-purple .header-topnav,
.egret-dark-purple .topnav ul ul,
.egret-dark-purple .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.egret-dark-purple .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group,
.egret-dark-purple .cal-month-view .cal-cell-row .cal-cell:hover,
.egret-dark-purple .cal-month-view .cal-cell.cal-has-events.cal-open,
.egret-dark-purple .cal-week-view .cal-day-headers .cal-header:hover,
.egret-dark-purple .cal-week-view .cal-day-headers .cal-drag-over,
.egret-dark-purple .cal-day-view .cal-hour-segment:hover,
.egret-dark-purple .cal-day-view .cal-drag-over .cal-hour-segment {
  background: #333 !important;
}
.egret-dark-purple .cal-month-view .cal-day-cell:not(:last-child),
.egret-dark-purple .cal-month-view .cal-days,
.egret-dark-purple .cal-week-view .cal-day-headers .cal-header:not(:last-child),
.egret-dark-purple .cal-week-view .cal-day-headers,
.egret-dark-purple .cal-month-view .cal-days .cal-cell-row,
.egret-dark-purple .cal-day-view .cal-hour:not(:last-child) .cal-hour-segment,
.egret-dark-purple .cal-day-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-color: #000 !important;
}
.egret-dark-purple div.hopscotch-bubble {
  border-color: #7e208f;
}
.egret-dark-purple div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom: 17px solid #7e208f;
}
.egret-dark-purple div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
  border-bottom: 17px solid #9c27b0;
  top: -16px;
}
.egret-dark-purple div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left: 17px solid #7e208f;
}
.egret-dark-purple div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
  border-left: 17px solid #9c27b0;
  left: -1px;
}
.egret-dark-purple div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top: 17px solid #7e208f;
}
.egret-dark-purple div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
  border-top: 17px solid #9c27b0;
  top: -18px;
}
.egret-dark-purple div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
  border-right: 17px solid #7e208f;
}
.egret-dark-purple div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
  left: 1px;
  border-right: 17px solid #9c27b0;
}
.egret-dark-purple .mat-bg-accent,
.egret-dark-purple div.hopscotch-bubble .hopscotch-bubble-number,
.egret-dark-purple div.hopscotch-bubble .hopscotch-nav-button.next:hover,
.egret-dark-purple div.hopscotch-bubble .hopscotch-nav-button.next {
  background-color: #4caf50;
  color: black;
}
.egret-dark-purple .mat-bg-warn {
  background-color: #f44336;
  color: white;
}
.egret-dark-purple .mat-color-accent {
  color: #4caf50;
}
.egret-dark-purple .mat-color-warn {
  color: #f44336;
}
.egret-dark-purple .mat-color-default {
  color: #ffffff;
}

.egret-dark-pink {
  /* Hopscotch Tour */
  /* up arrow  */
  /* right arrow */
  /* bottom arrow */
  /* Left Arrow */
}
.egret-dark-pink .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-dark-pink .mat-option {
  color: white;
}
.egret-dark-pink .mat-option:hover:not(.mat-option-disabled), .egret-dark-pink .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.egret-dark-pink .mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(255, 255, 255, 0.04);
}
.egret-dark-pink .mat-option.mat-active {
  background: rgba(255, 255, 255, 0.04);
  color: white;
}
.egret-dark-pink .mat-option.mat-option-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #e91e63;
}
.egret-dark-pink .mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #607d8b;
}
.egret-dark-pink .mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}
.egret-dark-pink .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-pseudo-checkbox {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-pseudo-checkbox::after {
  color: #303030;
}
.egret-dark-pink .mat-pseudo-checkbox-disabled {
  color: #686868;
}
.egret-dark-pink .mat-primary .mat-pseudo-checkbox-checked,
.egret-dark-pink .mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #e91e63;
}
.egret-dark-pink .mat-pseudo-checkbox-checked,
.egret-dark-pink .mat-pseudo-checkbox-indeterminate,
.egret-dark-pink .mat-accent .mat-pseudo-checkbox-checked,
.egret-dark-pink .mat-accent .mat-pseudo-checkbox-indeterminate {
  background: #607d8b;
}
.egret-dark-pink .mat-warn .mat-pseudo-checkbox-checked,
.egret-dark-pink .mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}
.egret-dark-pink .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.egret-dark-pink .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #686868;
}
.egret-dark-pink .mat-app-background, .egret-dark-pink.mat-app-background {
  background-color: #303030;
  color: white;
}
.egret-dark-pink .mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.egret-dark-pink .mat-autocomplete-panel {
  background: #424242;
  color: white;
}
.egret-dark-pink .mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #424242;
}
.egret-dark-pink .mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: white;
}
.egret-dark-pink .mat-badge-content {
  color: white;
  background: #e91e63;
}
.cdk-high-contrast-active .egret-dark-pink .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.egret-dark-pink .mat-badge-accent .mat-badge-content {
  background: #607d8b;
  color: white;
}
.egret-dark-pink .mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}
.egret-dark-pink .mat-badge {
  position: relative;
}
.egret-dark-pink .mat-badge-hidden .mat-badge-content {
  display: none;
}
.egret-dark-pink .mat-badge-disabled .mat-badge-content {
  background: #6e6e6e;
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}
.egret-dark-pink .ng-animate-disabled .mat-badge-content,
.egret-dark-pink .mat-badge-content._mat-animation-noopable {
  transition: none;
}
.egret-dark-pink .mat-badge-content.mat-badge-active {
  transform: none;
}
.egret-dark-pink .mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.egret-dark-pink .mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.egret-dark-pink .mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.egret-dark-pink .mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .egret-dark-pink .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.egret-dark-pink .mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .egret-dark-pink .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.egret-dark-pink .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .egret-dark-pink .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.egret-dark-pink .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .egret-dark-pink .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}
.egret-dark-pink .mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.egret-dark-pink .mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.egret-dark-pink .mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.egret-dark-pink .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .egret-dark-pink .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.egret-dark-pink .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .egret-dark-pink .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.egret-dark-pink .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .egret-dark-pink .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.egret-dark-pink .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .egret-dark-pink .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}
.egret-dark-pink .mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.egret-dark-pink .mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.egret-dark-pink .mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.egret-dark-pink .mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .egret-dark-pink .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.egret-dark-pink .mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .egret-dark-pink .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.egret-dark-pink .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .egret-dark-pink .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.egret-dark-pink .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .egret-dark-pink .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}
.egret-dark-pink .mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.egret-dark-pink .mat-button, .egret-dark-pink .mat-icon-button, .egret-dark-pink .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.egret-dark-pink .mat-button.mat-primary, .egret-dark-pink .mat-icon-button.mat-primary, .egret-dark-pink .mat-stroked-button.mat-primary {
  color: #e91e63;
}
.egret-dark-pink .mat-button.mat-accent, .egret-dark-pink .mat-icon-button.mat-accent, .egret-dark-pink .mat-stroked-button.mat-accent {
  color: #607d8b;
}
.egret-dark-pink .mat-button.mat-warn, .egret-dark-pink .mat-icon-button.mat-warn, .egret-dark-pink .mat-stroked-button.mat-warn {
  color: #f44336;
}
.egret-dark-pink .mat-button.mat-primary.mat-button-disabled, .egret-dark-pink .mat-button.mat-accent.mat-button-disabled, .egret-dark-pink .mat-button.mat-warn.mat-button-disabled, .egret-dark-pink .mat-button.mat-button-disabled.mat-button-disabled, .egret-dark-pink .mat-icon-button.mat-primary.mat-button-disabled, .egret-dark-pink .mat-icon-button.mat-accent.mat-button-disabled, .egret-dark-pink .mat-icon-button.mat-warn.mat-button-disabled, .egret-dark-pink .mat-icon-button.mat-button-disabled.mat-button-disabled, .egret-dark-pink .mat-stroked-button.mat-primary.mat-button-disabled, .egret-dark-pink .mat-stroked-button.mat-accent.mat-button-disabled, .egret-dark-pink .mat-stroked-button.mat-warn.mat-button-disabled, .egret-dark-pink .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.egret-dark-pink .mat-button.mat-primary .mat-button-focus-overlay, .egret-dark-pink .mat-icon-button.mat-primary .mat-button-focus-overlay, .egret-dark-pink .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #e91e63;
}
.egret-dark-pink .mat-button.mat-accent .mat-button-focus-overlay, .egret-dark-pink .mat-icon-button.mat-accent .mat-button-focus-overlay, .egret-dark-pink .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: #607d8b;
}
.egret-dark-pink .mat-button.mat-warn .mat-button-focus-overlay, .egret-dark-pink .mat-icon-button.mat-warn .mat-button-focus-overlay, .egret-dark-pink .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.egret-dark-pink .mat-button.mat-button-disabled .mat-button-focus-overlay, .egret-dark-pink .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .egret-dark-pink .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.egret-dark-pink .mat-button .mat-ripple-element, .egret-dark-pink .mat-icon-button .mat-ripple-element, .egret-dark-pink .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}
.egret-dark-pink .mat-button-focus-overlay {
  background: white;
}
.egret-dark-pink .mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-flat-button, .egret-dark-pink .mat-raised-button, .egret-dark-pink .mat-fab, .egret-dark-pink .mat-mini-fab {
  color: white;
  background-color: #424242;
}
.egret-dark-pink .mat-flat-button.mat-primary, .egret-dark-pink .mat-raised-button.mat-primary, .egret-dark-pink .mat-fab.mat-primary, .egret-dark-pink .mat-mini-fab.mat-primary {
  color: white;
}
.egret-dark-pink .mat-flat-button.mat-accent, .egret-dark-pink .mat-raised-button.mat-accent, .egret-dark-pink .mat-fab.mat-accent, .egret-dark-pink .mat-mini-fab.mat-accent {
  color: white;
}
.egret-dark-pink .mat-flat-button.mat-warn, .egret-dark-pink .mat-raised-button.mat-warn, .egret-dark-pink .mat-fab.mat-warn, .egret-dark-pink .mat-mini-fab.mat-warn {
  color: white;
}
.egret-dark-pink .mat-flat-button.mat-primary.mat-button-disabled, .egret-dark-pink .mat-flat-button.mat-accent.mat-button-disabled, .egret-dark-pink .mat-flat-button.mat-warn.mat-button-disabled, .egret-dark-pink .mat-flat-button.mat-button-disabled.mat-button-disabled, .egret-dark-pink .mat-raised-button.mat-primary.mat-button-disabled, .egret-dark-pink .mat-raised-button.mat-accent.mat-button-disabled, .egret-dark-pink .mat-raised-button.mat-warn.mat-button-disabled, .egret-dark-pink .mat-raised-button.mat-button-disabled.mat-button-disabled, .egret-dark-pink .mat-fab.mat-primary.mat-button-disabled, .egret-dark-pink .mat-fab.mat-accent.mat-button-disabled, .egret-dark-pink .mat-fab.mat-warn.mat-button-disabled, .egret-dark-pink .mat-fab.mat-button-disabled.mat-button-disabled, .egret-dark-pink .mat-mini-fab.mat-primary.mat-button-disabled, .egret-dark-pink .mat-mini-fab.mat-accent.mat-button-disabled, .egret-dark-pink .mat-mini-fab.mat-warn.mat-button-disabled, .egret-dark-pink .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(255, 255, 255, 0.3);
}
.egret-dark-pink .mat-flat-button.mat-primary, .egret-dark-pink .mat-raised-button.mat-primary, .egret-dark-pink .mat-fab.mat-primary, .egret-dark-pink .mat-mini-fab.mat-primary {
  background-color: #e91e63;
}
.egret-dark-pink .mat-flat-button.mat-accent, .egret-dark-pink .mat-raised-button.mat-accent, .egret-dark-pink .mat-fab.mat-accent, .egret-dark-pink .mat-mini-fab.mat-accent {
  background-color: #607d8b;
}
.egret-dark-pink .mat-flat-button.mat-warn, .egret-dark-pink .mat-raised-button.mat-warn, .egret-dark-pink .mat-fab.mat-warn, .egret-dark-pink .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.egret-dark-pink .mat-flat-button.mat-primary.mat-button-disabled, .egret-dark-pink .mat-flat-button.mat-accent.mat-button-disabled, .egret-dark-pink .mat-flat-button.mat-warn.mat-button-disabled, .egret-dark-pink .mat-flat-button.mat-button-disabled.mat-button-disabled, .egret-dark-pink .mat-raised-button.mat-primary.mat-button-disabled, .egret-dark-pink .mat-raised-button.mat-accent.mat-button-disabled, .egret-dark-pink .mat-raised-button.mat-warn.mat-button-disabled, .egret-dark-pink .mat-raised-button.mat-button-disabled.mat-button-disabled, .egret-dark-pink .mat-fab.mat-primary.mat-button-disabled, .egret-dark-pink .mat-fab.mat-accent.mat-button-disabled, .egret-dark-pink .mat-fab.mat-warn.mat-button-disabled, .egret-dark-pink .mat-fab.mat-button-disabled.mat-button-disabled, .egret-dark-pink .mat-mini-fab.mat-primary.mat-button-disabled, .egret-dark-pink .mat-mini-fab.mat-accent.mat-button-disabled, .egret-dark-pink .mat-mini-fab.mat-warn.mat-button-disabled, .egret-dark-pink .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-flat-button.mat-primary .mat-ripple-element, .egret-dark-pink .mat-raised-button.mat-primary .mat-ripple-element, .egret-dark-pink .mat-fab.mat-primary .mat-ripple-element, .egret-dark-pink .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-dark-pink .mat-flat-button.mat-accent .mat-ripple-element, .egret-dark-pink .mat-raised-button.mat-accent .mat-ripple-element, .egret-dark-pink .mat-fab.mat-accent .mat-ripple-element, .egret-dark-pink .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-dark-pink .mat-flat-button.mat-warn .mat-ripple-element, .egret-dark-pink .mat-raised-button.mat-warn .mat-ripple-element, .egret-dark-pink .mat-fab.mat-warn .mat-ripple-element, .egret-dark-pink .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-dark-pink .mat-stroked-button:not([class*=mat-elevation-z]), .egret-dark-pink .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-fab:not([class*=mat-elevation-z]), .egret-dark-pink .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .egret-dark-pink .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .egret-dark-pink .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-button-toggle-standalone,
.egret-dark-pink .mat-button-toggle-group {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.egret-dark-pink .mat-button-toggle-group-appearance-standard {
  box-shadow: none;
}
.egret-dark-pink .mat-button-toggle {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-button-toggle-appearance-standard {
  color: white;
  background: #424242;
}
.egret-dark-pink .mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: white;
}
.egret-dark-pink .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
}
.egret-dark-pink [dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-button-toggle-checked {
  background-color: #212121;
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
}
.egret-dark-pink .mat-button-toggle-disabled {
  color: rgba(255, 255, 255, 0.3);
  background-color: black;
}
.egret-dark-pink .mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: #424242;
}
.egret-dark-pink .mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #424242;
}
.egret-dark-pink .mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.egret-dark-pink .mat-button-toggle-group-appearance-standard {
  border: solid 1px rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-card {
  background: #424242;
  color: white;
}
.egret-dark-pink .mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-card-subtitle {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-checkbox-checkmark {
  fill: #303030;
}
.egret-dark-pink .mat-checkbox-checkmark-path {
  stroke: #303030 !important;
}
.egret-dark-pink .mat-checkbox-mixedmark {
  background-color: #303030;
}
.egret-dark-pink .mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .egret-dark-pink .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #e91e63;
}
.egret-dark-pink .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .egret-dark-pink .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #607d8b;
}
.egret-dark-pink .mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .egret-dark-pink .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}
.egret-dark-pink .mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .egret-dark-pink .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #686868;
}
.egret-dark-pink .mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #686868;
}
.egret-dark-pink .mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-checkbox .mat-ripple-element {
  background-color: white;
}
.egret-dark-pink .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.egret-dark-pink .mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #e91e63;
}
.egret-dark-pink .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.egret-dark-pink .mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: #607d8b;
}
.egret-dark-pink .mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.egret-dark-pink .mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}
.egret-dark-pink .mat-chip.mat-standard-chip {
  background-color: #616161;
  color: white;
}
.egret-dark-pink .mat-chip.mat-standard-chip .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-dark-pink .mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.egret-dark-pink .mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.egret-dark-pink .mat-chip.mat-standard-chip::after {
  background: white;
}
.egret-dark-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #e91e63;
  color: white;
}
.egret-dark-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-dark-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-dark-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.egret-dark-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-dark-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-dark-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: #607d8b;
  color: white;
}
.egret-dark-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.egret-dark-pink .mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-dark-pink .mat-table {
  background: #424242;
}
.egret-dark-pink .mat-table thead, .egret-dark-pink .mat-table tbody, .egret-dark-pink .mat-table tfoot,
.egret-dark-pink mat-header-row, .egret-dark-pink mat-row, .egret-dark-pink mat-footer-row,
.egret-dark-pink [mat-header-row], .egret-dark-pink [mat-row], .egret-dark-pink [mat-footer-row],
.egret-dark-pink .mat-table-sticky {
  background: inherit;
}
.egret-dark-pink mat-row, .egret-dark-pink mat-header-row, .egret-dark-pink mat-footer-row,
.egret-dark-pink th.mat-header-cell, .egret-dark-pink td.mat-cell, .egret-dark-pink td.mat-footer-cell {
  border-bottom-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-header-cell {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-cell, .egret-dark-pink .mat-footer-cell {
  color: white;
}
.egret-dark-pink .mat-calendar-arrow {
  border-top-color: white;
}
.egret-dark-pink .mat-datepicker-toggle,
.egret-dark-pink .mat-datepicker-content .mat-calendar-next-button,
.egret-dark-pink .mat-datepicker-content .mat-calendar-previous-button {
  color: white;
}
.egret-dark-pink .mat-calendar-table-header {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-calendar-table-header-divider::after {
  background: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-calendar-body-label {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-calendar-body-cell-content,
.egret-dark-pink .mat-date-range-input-separator {
  color: white;
  border-color: transparent;
}
.egret-dark-pink .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.egret-dark-pink .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.egret-dark-pink .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 255, 255, 0.04);
}
.egret-dark-pink .mat-calendar-body-in-preview {
  color: rgba(255, 255, 255, 0.24);
}
.egret-dark-pink .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-pink .mat-calendar-body-in-range::before {
  background: rgba(233, 30, 99, 0.2);
}
.egret-dark-pink .mat-calendar-body-comparison-identical,
.egret-dark-pink .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-dark-pink .mat-calendar-body-comparison-bridge-start::before,
.egret-dark-pink [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-dark-pink .mat-calendar-body-comparison-bridge-end::before,
.egret-dark-pink [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(233, 30, 99, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-dark-pink .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-dark-pink .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-dark-pink .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-dark-pink .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-dark-pink .mat-calendar-body-selected {
  background-color: #e91e63;
  color: white;
}
.egret-dark-pink .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(233, 30, 99, 0.4);
}
.egret-dark-pink .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.egret-dark-pink .mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: #424242;
  color: white;
}
.egret-dark-pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(96, 125, 139, 0.2);
}
.egret-dark-pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.egret-dark-pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-dark-pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.egret-dark-pink .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(96, 125, 139, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-dark-pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.egret-dark-pink .mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(96, 125, 139, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-dark-pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-dark-pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-dark-pink .mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-dark-pink .mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-dark-pink .mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: #607d8b;
  color: white;
}
.egret-dark-pink .mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(96, 125, 139, 0.4);
}
.egret-dark-pink .mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.egret-dark-pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.egret-dark-pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.egret-dark-pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.egret-dark-pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.egret-dark-pink .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-dark-pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.egret-dark-pink .mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.egret-dark-pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.egret-dark-pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.egret-dark-pink .mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.egret-dark-pink .mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.egret-dark-pink .mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.egret-dark-pink .mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.egret-dark-pink .mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.egret-dark-pink .mat-datepicker-content-touch {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-datepicker-toggle-active {
  color: #e91e63;
}
.egret-dark-pink .mat-datepicker-toggle-active.mat-accent {
  color: #607d8b;
}
.egret-dark-pink .mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}
.egret-dark-pink .mat-date-range-input-inner[disabled] {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: #424242;
  color: white;
}
.egret-dark-pink .mat-divider {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-divider-vertical {
  border-right-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-expansion-panel {
  background: #424242;
  color: white;
}
.egret-dark-pink .mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-action-row {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .egret-dark-pink .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .egret-dark-pink .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .egret-dark-pink .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: #424242;
  }
}
.egret-dark-pink .mat-expansion-panel-header-title {
  color: white;
}
.egret-dark-pink .mat-expansion-panel-header-description,
.egret-dark-pink .mat-expansion-indicator::after {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(255, 255, 255, 0.3);
}
.egret-dark-pink .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.egret-dark-pink .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}
.egret-dark-pink .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-form-field.mat-focused .mat-form-field-label {
  color: #e91e63;
}
.egret-dark-pink .mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: #607d8b;
}
.egret-dark-pink .mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}
.egret-dark-pink .mat-focused .mat-form-field-required-marker {
  color: #607d8b;
}
.egret-dark-pink .mat-form-field-ripple {
  background-color: white;
}
.egret-dark-pink .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #e91e63;
}
.egret-dark-pink .mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: #607d8b;
}
.egret-dark-pink .mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}
.egret-dark-pink .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #e91e63;
}
.egret-dark-pink .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: #607d8b;
}
.egret-dark-pink .mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}
.egret-dark-pink .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.egret-dark-pink .mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.egret-dark-pink .mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.egret-dark-pink .mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.egret-dark-pink .mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}
.egret-dark-pink .mat-error {
  color: #f44336;
}
.egret-dark-pink .mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-form-field-appearance-legacy .mat-hint {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.egret-dark-pink .mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}
.egret-dark-pink .mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.1);
}
.egret-dark-pink .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(255, 255, 255, 0.05);
}
.egret-dark-pink .mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}
.egret-dark-pink .mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.3);
}
.egret-dark-pink .mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: white;
}
.egret-dark-pink .mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #e91e63;
}
.egret-dark-pink .mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: #607d8b;
}
.egret-dark-pink .mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.egret-dark-pink .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.egret-dark-pink .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(255, 255, 255, 0.15);
}
.egret-dark-pink .mat-icon.mat-primary {
  color: #e91e63;
}
.egret-dark-pink .mat-icon.mat-accent {
  color: #607d8b;
}
.egret-dark-pink .mat-icon.mat-warn {
  color: #f44336;
}
.egret-dark-pink .mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-input-element:disabled,
.egret-dark-pink .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-input-element {
  caret-color: #e91e63;
}
.egret-dark-pink .mat-input-element::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-input-element::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-input-element::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-input-element:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-input-element option {
  color: rgba(0, 0, 0, 0.87);
}
.egret-dark-pink .mat-input-element option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.egret-dark-pink .mat-form-field.mat-accent .mat-input-element {
  caret-color: #607d8b;
}
.egret-dark-pink .mat-form-field.mat-warn .mat-input-element,
.egret-dark-pink .mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}
.egret-dark-pink .mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}
.egret-dark-pink .mat-list-base .mat-list-item {
  color: white;
}
.egret-dark-pink .mat-list-base .mat-list-option {
  color: white;
}
.egret-dark-pink .mat-list-base .mat-subheader {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-list-item-disabled {
  background-color: black;
}
.egret-dark-pink .mat-list-option:hover, .egret-dark-pink .mat-list-option:focus,
.egret-dark-pink .mat-nav-list .mat-list-item:hover,
.egret-dark-pink .mat-nav-list .mat-list-item:focus,
.egret-dark-pink .mat-action-list .mat-list-item:hover,
.egret-dark-pink .mat-action-list .mat-list-item:focus {
  background: rgba(255, 255, 255, 0.04);
}
.egret-dark-pink .mat-list-single-selected-option, .egret-dark-pink .mat-list-single-selected-option:hover, .egret-dark-pink .mat-list-single-selected-option:focus {
  background: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-menu-panel {
  background: #424242;
}
.egret-dark-pink .mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-menu-item {
  background: transparent;
  color: white;
}
.egret-dark-pink .mat-menu-item[disabled], .egret-dark-pink .mat-menu-item[disabled]::after {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-menu-item .mat-icon-no-color,
.egret-dark-pink .mat-menu-item-submenu-trigger::after {
  color: white;
}
.egret-dark-pink .mat-menu-item:hover:not([disabled]),
.egret-dark-pink .mat-menu-item.cdk-program-focused:not([disabled]),
.egret-dark-pink .mat-menu-item.cdk-keyboard-focused:not([disabled]),
.egret-dark-pink .mat-menu-item-highlighted:not([disabled]) {
  background: rgba(255, 255, 255, 0.04);
}
.egret-dark-pink .mat-paginator {
  background: #424242;
}
.egret-dark-pink .mat-paginator,
.egret-dark-pink .mat-paginator-page-size .mat-select-trigger {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-paginator-decrement,
.egret-dark-pink .mat-paginator-increment {
  border-top: 2px solid white;
  border-right: 2px solid white;
}
.egret-dark-pink .mat-paginator-first,
.egret-dark-pink .mat-paginator-last {
  border-top: 2px solid white;
}
.egret-dark-pink .mat-icon-button[disabled] .mat-paginator-decrement,
.egret-dark-pink .mat-icon-button[disabled] .mat-paginator-increment,
.egret-dark-pink .mat-icon-button[disabled] .mat-paginator-first,
.egret-dark-pink .mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-progress-bar-background {
  fill: #f8bcd0;
}
.egret-dark-pink .mat-progress-bar-buffer {
  background-color: #f8bcd0;
}
.egret-dark-pink .mat-progress-bar-fill::after {
  background-color: #e91e63;
}
.egret-dark-pink .mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #cfd8dc;
}
.egret-dark-pink .mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #cfd8dc;
}
.egret-dark-pink .mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: #607d8b;
}
.egret-dark-pink .mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #ffcdd2;
}
.egret-dark-pink .mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #ffcdd2;
}
.egret-dark-pink .mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}
.egret-dark-pink .mat-progress-spinner circle, .egret-dark-pink .mat-spinner circle {
  stroke: #e91e63;
}
.egret-dark-pink .mat-progress-spinner.mat-accent circle, .egret-dark-pink .mat-spinner.mat-accent circle {
  stroke: #607d8b;
}
.egret-dark-pink .mat-progress-spinner.mat-warn circle, .egret-dark-pink .mat-spinner.mat-warn circle {
  stroke: #f44336;
}
.egret-dark-pink .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #e91e63;
}
.egret-dark-pink .mat-radio-button.mat-primary .mat-radio-inner-circle,
.egret-dark-pink .mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-dark-pink .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .egret-dark-pink .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #e91e63;
}
.egret-dark-pink .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #607d8b;
}
.egret-dark-pink .mat-radio-button.mat-accent .mat-radio-inner-circle,
.egret-dark-pink .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-dark-pink .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .egret-dark-pink .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: #607d8b;
}
.egret-dark-pink .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.egret-dark-pink .mat-radio-button.mat-warn .mat-radio-inner-circle,
.egret-dark-pink .mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .egret-dark-pink .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .egret-dark-pink .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.egret-dark-pink .mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.egret-dark-pink .mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.egret-dark-pink .mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-radio-button .mat-ripple-element {
  background-color: white;
}
.egret-dark-pink .mat-select-value {
  color: white;
}
.egret-dark-pink .mat-select-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-select-disabled .mat-select-value {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-select-arrow {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-select-panel {
  background: #424242;
}
.egret-dark-pink .mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #e91e63;
}
.egret-dark-pink .mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: #607d8b;
}
.egret-dark-pink .mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.egret-dark-pink .mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.egret-dark-pink .mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-drawer-container {
  background-color: #303030;
  color: white;
}
.egret-dark-pink .mat-drawer {
  background-color: #424242;
  color: white;
}
.egret-dark-pink .mat-drawer.mat-drawer-push {
  background-color: #424242;
}
.egret-dark-pink .mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-drawer-side {
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.egret-dark-pink [dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(255, 255, 255, 0.12);
  border-right: none;
}
.egret-dark-pink [dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(189, 189, 189, 0.6);
}
.egret-dark-pink .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #607d8b;
}
.egret-dark-pink .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(96, 125, 139, 0.54);
}
.egret-dark-pink .mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: #607d8b;
}
.egret-dark-pink .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #e91e63;
}
.egret-dark-pink .mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(233, 30, 99, 0.54);
}
.egret-dark-pink .mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #e91e63;
}
.egret-dark-pink .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.egret-dark-pink .mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.egret-dark-pink .mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.egret-dark-pink .mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: white;
}
.egret-dark-pink .mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #bdbdbd;
}
.egret-dark-pink .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-pink .mat-primary .mat-slider-track-fill,
.egret-dark-pink .mat-primary .mat-slider-thumb,
.egret-dark-pink .mat-primary .mat-slider-thumb-label {
  background-color: #e91e63;
}
.egret-dark-pink .mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.egret-dark-pink .mat-primary .mat-slider-focus-ring {
  background-color: rgba(233, 30, 99, 0.2);
}
.egret-dark-pink .mat-accent .mat-slider-track-fill,
.egret-dark-pink .mat-accent .mat-slider-thumb,
.egret-dark-pink .mat-accent .mat-slider-thumb-label {
  background-color: #607d8b;
}
.egret-dark-pink .mat-accent .mat-slider-thumb-label-text {
  color: white;
}
.egret-dark-pink .mat-accent .mat-slider-focus-ring {
  background-color: rgba(96, 125, 139, 0.2);
}
.egret-dark-pink .mat-warn .mat-slider-track-fill,
.egret-dark-pink .mat-warn .mat-slider-thumb,
.egret-dark-pink .mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.egret-dark-pink .mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.egret-dark-pink .mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.egret-dark-pink .mat-slider:hover .mat-slider-track-background,
.egret-dark-pink .cdk-focused .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-pink .mat-slider-disabled .mat-slider-track-background,
.egret-dark-pink .mat-slider-disabled .mat-slider-track-fill,
.egret-dark-pink .mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-pink .mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-pink .mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.egret-dark-pink .mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: white;
}
.egret-dark-pink .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.egret-dark-pink .mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-pink .mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
  background-color: transparent;
}
.egret-dark-pink .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .egret-dark-pink .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-pink .mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .egret-dark-pink .mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(255, 255, 255, 0.3);
}
.egret-dark-pink .mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.egret-dark-pink .mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7) 2px, transparent 0, transparent);
}
.egret-dark-pink .mat-step-header.cdk-keyboard-focused, .egret-dark-pink .mat-step-header.cdk-program-focused, .egret-dark-pink .mat-step-header:hover {
  background-color: rgba(255, 255, 255, 0.04);
}
@media (hover: none) {
  .egret-dark-pink .mat-step-header:hover {
    background: none;
  }
}
.egret-dark-pink .mat-step-header .mat-step-label,
.egret-dark-pink .mat-step-header .mat-step-optional {
  color: rgba(255, 255, 255, 0.7);
}
.egret-dark-pink .mat-step-header .mat-step-icon {
  background-color: rgba(255, 255, 255, 0.7);
  color: white;
}
.egret-dark-pink .mat-step-header .mat-step-icon-selected,
.egret-dark-pink .mat-step-header .mat-step-icon-state-done,
.egret-dark-pink .mat-step-header .mat-step-icon-state-edit {
  background-color: #e91e63;
  color: white;
}
.egret-dark-pink .mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.egret-dark-pink .mat-step-header .mat-step-label.mat-step-label-active {
  color: white;
}
.egret-dark-pink .mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}
.egret-dark-pink .mat-stepper-horizontal, .egret-dark-pink .mat-stepper-vertical {
  background-color: #424242;
}
.egret-dark-pink .mat-stepper-vertical-line::before {
  border-left-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-horizontal-stepper-header::before,
.egret-dark-pink .mat-horizontal-stepper-header::after,
.egret-dark-pink .mat-stepper-horizontal-line {
  border-top-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-sort-header-arrow {
  color: #c6c6c6;
}
.egret-dark-pink .mat-tab-nav-bar,
.egret-dark-pink .mat-tab-header {
  border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-tab-group-inverted-header .mat-tab-nav-bar,
.egret-dark-pink .mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(255, 255, 255, 0.12);
  border-bottom: none;
}
.egret-dark-pink .mat-tab-label, .egret-dark-pink .mat-tab-link {
  color: white;
}
.egret-dark-pink .mat-tab-label.mat-tab-disabled, .egret-dark-pink .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-tab-header-pagination-chevron {
  border-color: white;
}
.egret-dark-pink .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.5);
}
.egret-dark-pink .mat-tab-group[class*=mat-background-] .mat-tab-header,
.egret-dark-pink .mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}
.egret-dark-pink .mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 188, 208, 0.3);
}
.egret-dark-pink .mat-tab-group.mat-primary .mat-ink-bar, .egret-dark-pink .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #e91e63;
}
.egret-dark-pink .mat-tab-group.mat-primary.mat-background-primary .mat-ink-bar, .egret-dark-pink .mat-tab-nav-bar.mat-primary.mat-background-primary .mat-ink-bar {
  background-color: white;
}
.egret-dark-pink .mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.egret-dark-pink .mat-tab-group.mat-accent .mat-ink-bar, .egret-dark-pink .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: #607d8b;
}
.egret-dark-pink .mat-tab-group.mat-accent.mat-background-accent .mat-ink-bar, .egret-dark-pink .mat-tab-nav-bar.mat-accent.mat-background-accent .mat-ink-bar {
  background-color: white;
}
.egret-dark-pink .mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.egret-dark-pink .mat-tab-group.mat-warn .mat-ink-bar, .egret-dark-pink .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.egret-dark-pink .mat-tab-group.mat-warn.mat-background-warn .mat-ink-bar, .egret-dark-pink .mat-tab-nav-bar.mat-warn.mat-background-warn .mat-ink-bar {
  background-color: white;
}
.egret-dark-pink .mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(248, 188, 208, 0.3);
}
.egret-dark-pink .mat-tab-group.mat-background-primary .mat-tab-header, .egret-dark-pink .mat-tab-group.mat-background-primary .mat-tab-links, .egret-dark-pink .mat-tab-group.mat-background-primary .mat-tab-header-pagination, .egret-dark-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-header, .egret-dark-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-links, .egret-dark-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination {
  background-color: #e91e63;
}
.egret-dark-pink .mat-tab-group.mat-background-primary .mat-tab-label, .egret-dark-pink .mat-tab-group.mat-background-primary .mat-tab-link, .egret-dark-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-label, .egret-dark-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-link {
  color: white;
}
.egret-dark-pink .mat-tab-group.mat-background-primary .mat-tab-label.mat-tab-disabled, .egret-dark-pink .mat-tab-group.mat-background-primary .mat-tab-link.mat-tab-disabled, .egret-dark-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-label.mat-tab-disabled, .egret-dark-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.egret-dark-pink .mat-tab-group.mat-background-primary .mat-tab-header-pagination-chevron, .egret-dark-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-chevron {
  border-color: white;
}
.egret-dark-pink .mat-tab-group.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-dark-pink .mat-tab-nav-bar.mat-background-primary .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.egret-dark-pink .mat-tab-group.mat-background-primary .mat-ripple-element, .egret-dark-pink .mat-tab-nav-bar.mat-background-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(207, 216, 220, 0.3);
}
.egret-dark-pink .mat-tab-group.mat-background-accent .mat-tab-header, .egret-dark-pink .mat-tab-group.mat-background-accent .mat-tab-links, .egret-dark-pink .mat-tab-group.mat-background-accent .mat-tab-header-pagination, .egret-dark-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-header, .egret-dark-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-links, .egret-dark-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination {
  background-color: #607d8b;
}
.egret-dark-pink .mat-tab-group.mat-background-accent .mat-tab-label, .egret-dark-pink .mat-tab-group.mat-background-accent .mat-tab-link, .egret-dark-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-label, .egret-dark-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-link {
  color: white;
}
.egret-dark-pink .mat-tab-group.mat-background-accent .mat-tab-label.mat-tab-disabled, .egret-dark-pink .mat-tab-group.mat-background-accent .mat-tab-link.mat-tab-disabled, .egret-dark-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-label.mat-tab-disabled, .egret-dark-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.egret-dark-pink .mat-tab-group.mat-background-accent .mat-tab-header-pagination-chevron, .egret-dark-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-chevron {
  border-color: white;
}
.egret-dark-pink .mat-tab-group.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-dark-pink .mat-tab-nav-bar.mat-background-accent .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.egret-dark-pink .mat-tab-group.mat-background-accent .mat-ripple-element, .egret-dark-pink .mat-tab-nav-bar.mat-background-accent .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .egret-dark-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.egret-dark-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.egret-dark-pink .mat-tab-group.mat-background-warn .mat-tab-header, .egret-dark-pink .mat-tab-group.mat-background-warn .mat-tab-links, .egret-dark-pink .mat-tab-group.mat-background-warn .mat-tab-header-pagination, .egret-dark-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-header, .egret-dark-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-links, .egret-dark-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination {
  background-color: #f44336;
}
.egret-dark-pink .mat-tab-group.mat-background-warn .mat-tab-label, .egret-dark-pink .mat-tab-group.mat-background-warn .mat-tab-link, .egret-dark-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-label, .egret-dark-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-link {
  color: white;
}
.egret-dark-pink .mat-tab-group.mat-background-warn .mat-tab-label.mat-tab-disabled, .egret-dark-pink .mat-tab-group.mat-background-warn .mat-tab-link.mat-tab-disabled, .egret-dark-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-label.mat-tab-disabled, .egret-dark-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.egret-dark-pink .mat-tab-group.mat-background-warn .mat-tab-header-pagination-chevron, .egret-dark-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-chevron {
  border-color: white;
}
.egret-dark-pink .mat-tab-group.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .egret-dark-pink .mat-tab-nav-bar.mat-background-warn .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(255, 255, 255, 0.4);
}
.egret-dark-pink .mat-tab-group.mat-background-warn .mat-ripple-element, .egret-dark-pink .mat-tab-nav-bar.mat-background-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.12);
}
.egret-dark-pink .mat-toolbar {
  background: #212121;
  color: white;
}
.egret-dark-pink .mat-toolbar.mat-primary {
  background: #e91e63;
  color: white;
}
.egret-dark-pink .mat-toolbar.mat-accent {
  background: #607d8b;
  color: white;
}
.egret-dark-pink .mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.egret-dark-pink .mat-toolbar .mat-form-field-underline,
.egret-dark-pink .mat-toolbar .mat-form-field-ripple,
.egret-dark-pink .mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.egret-dark-pink .mat-toolbar .mat-form-field-label,
.egret-dark-pink .mat-toolbar .mat-focused .mat-form-field-label,
.egret-dark-pink .mat-toolbar .mat-select-value,
.egret-dark-pink .mat-toolbar .mat-select-arrow,
.egret-dark-pink .mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.egret-dark-pink .mat-toolbar .mat-input-element {
  caret-color: currentColor;
}
.egret-dark-pink .mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}
.egret-dark-pink .mat-tree {
  background: #424242;
}
.egret-dark-pink .mat-tree-node,
.egret-dark-pink .mat-nested-tree-node {
  color: white;
}
.egret-dark-pink .mat-snack-bar-container {
  color: rgba(0, 0, 0, 0.87);
  background: #fafafa;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.egret-dark-pink .mat-simple-snackbar-action {
  color: inherit;
}
.egret-dark-pink .mat-bg-primary,
.egret-dark-pink .topbar,
.egret-dark-pink .chats-wrap .conversations-hold .single-conversation.me .conversation-msg,
.egret-dark-pink .ngx-datatable .datatable-footer .datatable-pager .pager .pages.active a,
.egret-dark-pink .fileupload-drop-zone.dz-file-over,
.egret-dark-pink .toolbar-avatar.online > .status-dot,
.egret-dark-pink .cal-open-day-events,
.egret-dark-pink div.hopscotch-bubble {
  background: #e91e63 !important;
  color: #ffffff !important;
}
.egret-dark-pink .mat-color-primary,
.egret-dark-pink .list-item-active,
.egret-dark-pink .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.egret-dark-pink .sidenav-hold .sidenav li.open > div > a > span:not(.menuitem-badge),
.egret-dark-pink .sidenav-hold .sidenav li.open > a > span {
  color: #e91e63 !important;
}
.egret-dark-pink .sidebar-panel .sidebar-list-item.open > .mat-list-item-content > a > span,
.egret-dark-pink .sidebar-panel .sidebar-list-item.open .sub-menu .mat-list-item.selected a {
  font-weight: 500;
}
.egret-dark-pink .topnav ul.menu > li > div.open > a,
.egret-dark-pink .sidebar-panel .sidebar-list-item.open > .mat-list-item-content > .sub-menu,
.egret-dark-pink .sidenav-hold .sidenav li.open a .mat-icon:not(.menu-caret),
.egret-dark-pink .list-item-active {
  border-color: #e91e63 !important;
}
.egret-dark-pink .ngx-datatable.material,
.egret-dark-pink .messages-wrap .inbox-hold {
  background: transparent !important;
}
.egret-dark-pink .header-topnav,
.egret-dark-pink .topnav ul ul,
.egret-dark-pink .app-user .app-user-name,
.egret-dark-pink .sidebar-panel .sidebar-list-item mat-icon:not(.menu-caret),
.egret-dark-pink .ngx-datatable.material *,
.egret-dark-pink .ql-snow .ql-picker,
.egret-dark-pink .ngx-pagination a, .egret-dark-pink .ngx-pagination button {
  color: white !important;
}
.egret-dark-pink .text-muted {
  color: rgba(255, 255, 255, 0.54) !important;
}
.egret-dark-pink .ql-snow .ql-stroke {
  stroke: #ffffff;
}
.egret-dark-pink .ql-snow .ql-fill {
  fill: #ffffff;
}
.egret-dark-pink .ql-toolbar.ql-snow,
.egret-dark-pink .ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-color: rgba(255, 255, 255, 0.12) !important;
}
.egret-dark-pink .sidebar-panel .navigation-hold,
.egret-dark-pink .message-item.open .message-head,
.egret-dark-pink .ngx-datatable.material .datatable-header,
.egret-dark-pink .bg-white,
.egret-dark-pink .cal-month-view .cal-cell-row:hover,
.egret-dark-pink .chats-wrap .conversations-hold .single-conversation.sender .conversation-msg,
.egret-dark-pink .cal-day-view .cal-hour:nth-child(odd), .egret-dark-pink.collapsed-menu .sidebar-panel .sidebar-list-item.open .sub-menu,
.egret-dark-pink .ngx-pagination a:hover, .egret-dark-pink .ngx-pagination button:hover,
.egret-dark-pink .product-container .products-pagination .ngx-pagination .current {
  background: rgba(66, 66, 66, 0.92) !important;
}
.egret-dark-pink .sidebar-compact ul.submenu,
.egret-dark-pink .topnav ul li ul li:hover,
.egret-dark-pink .topnav ul li ul li.open,
.egret-dark-pink .default-bg {
  background: #424242;
  color: #ffffff;
}
.egret-dark-pink .header-topnav,
.egret-dark-pink .topnav ul ul,
.egret-dark-pink .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover,
.egret-dark-pink .ngx-datatable.material:not(.cell-selection) .datatable-body-row:hover .datatable-row-group,
.egret-dark-pink .cal-month-view .cal-cell-row .cal-cell:hover,
.egret-dark-pink .cal-month-view .cal-cell.cal-has-events.cal-open,
.egret-dark-pink .cal-week-view .cal-day-headers .cal-header:hover,
.egret-dark-pink .cal-week-view .cal-day-headers .cal-drag-over,
.egret-dark-pink .cal-day-view .cal-hour-segment:hover,
.egret-dark-pink .cal-day-view .cal-drag-over .cal-hour-segment {
  background: #333 !important;
}
.egret-dark-pink .cal-month-view .cal-day-cell:not(:last-child),
.egret-dark-pink .cal-month-view .cal-days,
.egret-dark-pink .cal-week-view .cal-day-headers .cal-header:not(:last-child),
.egret-dark-pink .cal-week-view .cal-day-headers,
.egret-dark-pink .cal-month-view .cal-days .cal-cell-row,
.egret-dark-pink .cal-day-view .cal-hour:not(:last-child) .cal-hour-segment,
.egret-dark-pink .cal-day-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-color: #000 !important;
}
.egret-dark-pink div.hopscotch-bubble {
  border-color: #ca1452;
}
.egret-dark-pink div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
  border-bottom: 17px solid #ca1452;
}
.egret-dark-pink div.hopscotch-bubble .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow {
  border-bottom: 17px solid #e91e63;
  top: -16px;
}
.egret-dark-pink div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
  border-left: 17px solid #ca1452;
}
.egret-dark-pink div.hopscotch-bubble .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow {
  border-left: 17px solid #e91e63;
  left: -1px;
}
.egret-dark-pink div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
  border-top: 17px solid #ca1452;
}
.egret-dark-pink div.hopscotch-bubble .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow {
  border-top: 17px solid #e91e63;
  top: -18px;
}
.egret-dark-pink div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
  border-right: 17px solid #ca1452;
}
.egret-dark-pink div.hopscotch-bubble .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow {
  left: 1px;
  border-right: 17px solid #e91e63;
}
.egret-dark-pink .mat-bg-accent,
.egret-dark-pink div.hopscotch-bubble .hopscotch-bubble-number,
.egret-dark-pink div.hopscotch-bubble .hopscotch-nav-button.next:hover,
.egret-dark-pink div.hopscotch-bubble .hopscotch-nav-button.next {
  background-color: #607d8b;
  color: black;
}
.egret-dark-pink .mat-bg-warn {
  background-color: #f44336;
  color: white;
}
.egret-dark-pink .mat-color-accent {
  color: #607d8b;
}
.egret-dark-pink .mat-color-warn {
  color: #f44336;
}
.egret-dark-pink .mat-color-default {
  color: #ffffff;
}

/* 
* REQUIRED STYLES 
*/
html {
  font-size: 16px;
}

html:not(.landing),
body:not(.landing) {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 0.875rem;
  color: rgba(0, 0, 0, 0.87);
  line-height: 1.5;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

[tabindex="-1"]:focus {
  outline: none;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

div {
  box-sizing: border-box;
}

html[dir=rtl], html[dir=ltr], body[dir=rtl], body[dir=ltr] {
  unicode-bidi: embed;
}

bdo[dir=rtl] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir=ltr] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

.mat-card {
  font-size: 0.875rem;
}

img:not(.mat-card-image) {
  max-width: 100%;
}

a, a:focus, a:hover {
  text-decoration: none;
}

a {
  color: inherit;
}

p {
  margin: 0 0 16px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
}

.h1,
h1 {
  font-size: 2rem;
}

.h2,
h2 {
  font-size: 1.75rem;
}

.h3,
h3 {
  font-size: 1.5rem;
}

.h4,
h4 {
  font-size: 1.25rem;
}

.h5,
h5 {
  font-size: 1rem;
}

.h6,
h6 {
  font-size: 0.875rem;
}

code {
  padding: 8px;
  background: rgba(0, 0, 0, 0.08);
}

/*---- Common -----*/
.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 15px;
}

.bg-none {
  background: transparent !important;
}

.bg-white {
  background: #ffffff !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 1rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-05 {
  margin-top: 0.5rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-05 {
  margin-right: 0.5rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-05 {
  margin-left: 0.5rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mb-05 {
  margin-bottom: 0.5rem !important;
}

.m-333 {
  margin: 0.333333rem !important;
}

.margin-333 {
  margin: 0.333333rem !important;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-1 {
  padding-right: 1rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 1rem !important;
}

.p-05 {
  padding: 0.5rem !important;
}

.height-100 {
  min-height: 100vh;
}

.fix {
  position: relative;
  overflow: hidden;
}

.fix-elm::after {
  display: table;
  width: 100%;
  content: "";
}

.mat-box-shadow {
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.light-gray {
  background: rgba(0, 0, 0, 0.024);
}

.light-mat-gray {
  background: rgba(0, 0, 0, 0.08);
}

.mat-blue {
  background: #247ba0;
  color: #FEFEFE;
}

.mat-red {
  background-color: #f44336 !important;
  color: #fff !important;
}

.mat-indigo {
  background-color: #6f8f99 !important;
  color: #fff !important;
}

.mat-brown {
  background-color: #785548 !important;
  color: #FEFEFE;
}

.mat-teal {
  background-color: #009688 !important;
  color: #fff !important;
}

.mat-purple {
  background-color: #9c27b0 !important;
  color: rgba(255, 255, 255, 0.87) !important;
}

.fz-1 {
  font-size: 1rem !important;
}

.fz-2 {
  font-size: 2rem !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.font-light {
  font-weight: 300 !important;
}

.font-normal {
  font-weight: normal !important;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.text-muted {
  color: rgba(0, 0, 0, 0.54) !important;
}

.text-muted-white {
  color: rgba(255, 255, 255, 0.54) !important;
}

.text-gray {
  color: rgba(0, 0, 0, 0.7) !important;
}

.text-sm {
  font-size: 0.813rem;
}

.list-item-active {
  border-left: 3px solid;
}

.material-icons.icon-sm {
  font-size: 18px !important;
  line-height: 18px !important;
  height: 18px;
  width: 18px;
}

.material-icons.icon-xs {
  font-size: 13px !important;
  line-height: 13px;
  height: 13px;
  width: 13px;
}

.mat-card.default {
  padding: 0;
}

.mat-card.default .mat-card-title {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
  font-weight: 400;
}

.mat-card.default > :first-child {
  border-radius: 2px 2px 0 0;
}

.mat-card.default .mat-card-subtitle:first-child {
  padding-top: 1rem;
}

.mat-card.default .mat-card-subtitle {
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 1;
}

.mat-card.default .mat-card-content {
  padding: 1rem;
  margin-bottom: 0;
  position: relative;
}

.mat-card.default .mat-card-actions,
.mat-card.default .mat-card-actions:last-child {
  padding: 0.5rem;
  margin: 0;
}

.mat-card.default > :last-child {
  border-radius: 0 0 2px 2px;
}

.mat-card {
  margin: 0.333333rem;
  overflow: hidden;
}

.mat-card .mat-card-title .mat-divider, .mat-divider.full-width {
  margin-left: -24px;
  margin-right: -24px;
}

.mat-card.p-0 .mat-card-title .card-title-text {
  padding: 1rem 1.5rem;
}

.mat-card.p-0 .mat-card-title .card-title-text .card-control {
  height: 24px;
  width: 24px;
  line-height: 24px;
}

.mat-card.p-0 .mat-card-title .card-title-text .mat-card-subtitle {
  margin: 0;
}

.mat-card.p-0 .mat-card-title .mat-divider {
  margin-left: 0;
  margin-right: 0;
  border-top-color: rgba(0, 0, 0, 0.04);
}

.mat-card.p-0 .mat-card-image {
  margin: 0 0 1rem !important;
}

.mat-card.p-0 .mat-card-content {
  padding: 0 1.5rem 1.5rem;
}

.mat-button,
.mat-raised-button {
  font-weight: 400 !important;
}

[mat-lg-button] {
  padding: 0 32px !important;
  font-size: 18px;
  line-height: 56px !important;
}

.mat-icon-button[mat-sm-button] {
  height: 24px;
  width: 24px;
  line-height: 24px;
}

.mat-icon-button[mat-xs-button] {
  height: 20px;
  width: 20px;
  line-height: 20px;
}

.mat-icon-button[mat-xs-button] .mat-icon {
  font-size: 16px;
  line-height: 20px !important;
  height: 20px;
  width: 20px;
}

.mat-chip[mat-sm-chip] {
  padding: 4px 6px 4px 6px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
  line-height: 12px !important;
  max-height: 20px;
  box-sizing: border-box;
}

.mat-icon-button.img-button img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
}

.compact-list .mat-list-item {
  line-height: 1.1;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.compact-list .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.08);
  cursor: pointer;
}

.list-tasktype .tasktype-item {
  padding: 12px;
}

.list-tasktype .tasktype-item:hover {
  background: rgba(0, 0, 0, 0.08);
}

.list-tasktype .tasktype-item .tasktype-action {
  visibility: hidden;
}

.list-tasktype .tasktype-item:hover .tasktype-action {
  visibility: visible;
}

.doughnut-grid {
  border-radius: 2px;
  overflow: hidden;
}

.doughnut-grid .doughnut-grid-item {
  padding: 1rem;
}

.doughnut-grid .doughnut-grid-item p {
  margin: 0;
}

.doughnut-grid .doughnut-grid-item .chart {
  margin: 0 0 8px;
}

.logo-group {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.toolbar-avatar {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 24px;
}

.toolbar-avatar > img {
  width: 40px !important;
  border-radius: 50%;
}

.toolbar-avatar.md,
.toolbar-avatar.md > img {
  width: 48px !important;
  height: 48px;
}

.toolbar-avatar > .status-dot {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: 25px;
  right: -2px;
  border: 2px solid #ffffff;
  background: #ccc;
}

.full-width {
  width: 100% !important;
}

.dnd-item {
  cursor: move;
  cursor: grab;
  cursor: -webkit-grab;
}

.icon-circle {
  color: white;
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.6rem;
  border-radius: 50%;
}

.mat-sidenav .mat-list-item:hover {
  background: rgba(0, 0, 0, 0.035) !important;
}

.mat-chip {
  position: relative;
  overflow: hidden;
}

.text-small {
  font-size: 0.813rem;
}

.text-small .mat-icon {
  font-size: 1rem;
  vertical-align: sub;
  margin: 0 2px;
}

.mat-card-title {
  font-size: 1rem !important;
}

.icon-chip {
  font-size: 11px !important;
  padding: 4px 8px !important;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon-chip .mat-icon {
  font-size: 16px;
  height: 16px;
  width: 16px;
  margin-right: 4px;
}

.mat-chip:not(.mat-basic-chip) {
  display: inline-block;
  padding: 8px 12px 8px 12px;
  border-radius: 24px;
  font-size: 13px;
  line-height: 16px;
}

.ql-container .ql-editor {
  min-height: 200px;
}

.chart {
  display: block;
  width: 100%;
}

.form-error-msg {
  color: #f44336;
  display: block;
  padding: 5px 0;
}

.accordion-handle {
  cursor: pointer;
}

.app-accordion {
  max-height: 200px;
  transition: max-height 0.3s ease;
}

.app-accordion.open {
  max-height: 1000px;
}

.app-accordion .accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.app-accordion.open .accordion-content {
  max-height: 800px;
}

.app-accordion.open .hidden-on-open {
  display: none !important;
}

.app-accordion:not(.open) .show-on-open {
  display: none !important;
}

mat-list a[mat-list-item] .mat-list-item-content,
mat-list mat-list-item .mat-list-item-content,
mat-nav-list a[mat-list-item] .mat-list-item-content,
mat-nav-list mat-list-item .mat-list-item-content,
[mat-menu-item],
.mat-option,
body .mat-select-trigger {
  font-size: 0.875rem !important;
}

.mat-ripple {
  position: relative;
}

.fileupload-drop-zone {
  text-align: center;
  border: 1px dashed rgba(0, 0, 0, 0.15);
  padding: 48px;
  height: 120px;
}

.default-table {
  text-align: left;
}

.default-table > thead tr th {
  font-weight: 400;
  padding: 0.9rem 1.2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.default-table tbody tr td {
  padding: 0.9rem 1.2rem;
}

.app-error {
  text-align: center;
  width: 320px;
  max-width: 320px;
  margin: 0 auto;
}

.app-error .error-icon {
  height: 120px;
  width: 120px;
  font-size: 120px;
  float: left;
}

.app-error .error-text {
  float: right;
  width: 200px;
  max-width: 200px;
}

.app-error .error-title {
  font-size: 5rem;
  font-weight: 900;
  margin: 0;
}

.app-error .error-subtitle {
  font-size: 1.5rem;
  font-weight: 300;
}

.app-error .error-actions {
  width: 100%;
  overflow: hidden;
  min-height: 54px;
  margin-top: 100px;
}

/*---- Loader ----*/
.app-loader,
.view-loader {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.view-loader {
  display: block;
  padding-top: 160px;
  background: rgba(255, 255, 255, 0.3);
  z-index: 9999;
}

.view-loader * {
  margin: auto;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
/*---- Third pirty adjust -----*/
/*------- quill rich text editor ----------*/
.p-0.mat-card-content .ql-container {
  border: 0 !important;
}

.p-0.mat-card-content .ql-toolbar.ql-snow {
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

/*--- Scroll Bar ---*/
.ps__scrollbar-y-rail {
  z-index: 999;
}

.collapsed-menu .ps__scrollbar-y-rail {
  z-index: auto;
}

/*--- Data table ---*/
.ngx-datatable.material {
  box-shadow: none !important;
}

/*------ Map ------*/
.agm-info-window-content {
  color: rgba(0, 0, 0, 0.87);
}

/*-------- Chart js ---------*/
.chart {
  margin-left: -10px;
}

/*-------- Hopscotch Tour ---------*/
div.hopscotch-bubble,
div.hopscotch-bubble .hopscotch-title,
div.hopscotch-bubble .hopscotch-content,
div.hopscotch-bubble .hopscotch-nav-button {
  font-family: "Roboto", Helvetica Neue, sans-serif !important;
}

div.hopscotch-bubble {
  border: 1px solid;
  border-radius: 2px;
}

div.hopscotch-bubble .hopscotch-bubble-number {
  border-radius: 50%;
  box-sizing: border-box;
  padding: 0;
  font-weight: 500;
  height: 32px;
  width: 32px;
  line-height: 32px;
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}

div.hopscotch-bubble .hopscotch-title {
  font-size: 17px;
  font-weight: 400;
  color: #ffffff;
  letter-spacing: 0.1px;
}

div.hopscotch-bubble .hopscotch-content {
  margin: -5px 0 16px;
}

div.hopscotch-bubble .hopscotch-bubble-close {
  background: url("../../../../assets/images/cancel.png");
  background-size: 8px;
  background-position: 8px 8px;
  background-repeat: no-repeat;
  cursor: pointer;
}

div.hopscotch-bubble .hopscotch-bubble-close:active {
  outline: 0;
}

/* up arrow  */
div.hopscotch-bubble .hopscotch-bubble-arrow-container.up {
  top: -17px;
}

/* right arrow */
div.hopscotch-bubble .hopscotch-bubble-arrow-container.right {
  right: -34px;
}

/* bottom arrow */
div.hopscotch-bubble .hopscotch-bubble-arrow-container.down {
  bottom: -34px;
}

/* Left Arrow */
div.hopscotch-bubble .hopscotch-bubble-arrow-container.left {
  left: -17px;
}

div.hopscotch-bubble .hopscotch-nav-button {
  border: 0;
  border-radius: 2px;
  font-weight: normal;
  text-shadow: none !important;
  padding: 0 18px;
  height: 30px;
  line-height: 30px;
  font-size: 13px;
  background-image: none !important;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  transform: translate3d(0, 0, 0);
  transition: background 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
}

div.hopscotch-bubble .hopscotch-nav-button:hover,
div.hopscotch-bubble .hopscotch-nav-button:active,
div.hopscotch-bubble .hopscotch-nav-button:focus {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  outline: none;
}

div.hopscotch-bubble .hopscotch-nav-button.prev,
div.hopscotch-bubble .hopscotch-nav-button.prev:hover {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
}

.ps__rail-y {
  right: 0 !important;
  left: auto !important;
}

[dir=rtl] .pr-1 {
  padding-left: 1rem;
  padding-right: 0 !important;
}
[dir=rtl] .mr-1 {
  margin-right: 0 !important;
  margin-left: 1rem;
}
[dir=rtl] .ps__rail-y {
  right: auto !important;
  left: 0 !important;
}
[dir=rtl] .ps__thumb-y {
  right: auto !important;
  left: 1px !important;
}
[dir=rtl] .ngx-datatable .datatable-footer .datatable-pager .pager {
  float: left;
}
[dir=rtl] .user-card .user-details .mat-icon {
  margin-right: 0;
  margin-left: 0.5rem;
}

@media (max-width: 767px) {
  .mat-card-title .mat-divider {
    margin-left: 0;
    margin-right: 0;
  }

  .accordion-handle {
    flex-direction: column !important;
  }

  .app-error .error-icon {
    height: 100px;
    width: 100px;
    font-size: 100px;
  }
}
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

a, a:focus, a:hover {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  outline: 0;
}

button {
  outline: 0;
}

figure {
  margin: 0;
}

strong, b {
  font-weight: 700;
}

.strikethrough {
  text-decoration: line-through;
}

.app-admin-container {
  max-width: 100%;
  height: 100vh;
}
.app-admin-container .rightside-content-hold {
  padding: 0.333333rem;
  overflow-x: hidden;
  position: relative;
  min-height: 450px;
}

.sidebar-panel {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: 10001;
  width: 15rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-panel .navigation-hold {
  position: absolute;
  height: 100%;
  width: 100%;
  padding-top: 64px;
  background: rgba(255, 255, 255, 0.95);
  left: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-panel ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main-content-wrap {
  position: relative;
  float: right;
  height: 100vh;
  transition: width 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.main-content-wrap.ps > .ps__rail-y {
  z-index: 9999;
}

.sidebar-backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  display: block;
  z-index: 10000;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-backdrop.visible {
  visibility: visible;
  opacity: 1;
}

.app-admin-container.sidebar-full:not(.navigation-top) .main-content-wrap {
  width: calc(100% - 15rem);
}
.app-admin-container.sidebar-full:not(.navigation-top) .sidebar-panel {
  width: 15rem;
  overflow: hidden;
}

.app-admin-container.sidebar-compact .main-content-wrap {
  width: calc(100% - 48px);
}
.app-admin-container.sidebar-compact .sidebar-panel {
  width: 48px;
}
.app-admin-container.sidebar-compact .sidebar-panel .branding {
  width: 48px;
}
.app-admin-container.sidebar-compact .sidebar-panel .branding .app-logo {
  margin: auto;
}
.app-admin-container.sidebar-compact .sidebar-panel .navigation-hold {
  overflow: visible !important;
}

.app-admin-container.sidebar-compact-big .main-content-wrap {
  width: calc(100% - 150px);
}
.app-admin-container.sidebar-compact-big .sidebar-panel {
  width: 150px;
}
.app-admin-container.sidebar-compact-big .sidebar-panel .branding {
  width: 150px;
}
.app-admin-container.sidebar-compact-big .sidebar-panel .branding .app-logo {
  margin: auto;
}
.app-admin-container.sidebar-compact-big .sidebar-panel .navigation-hold {
  overflow: visible !important;
}

.app-admin-container.sidebar-closed .sidebar-panel {
  overflow: hidden;
  left: -15rem;
}
.app-admin-container.sidebar-closed .sidebar-panel .branding {
  left: -15rem;
  width: 48px;
}
.app-admin-container.sidebar-closed .main-content-wrap {
  width: 100%;
}

.fixed-topbar .rightside-content-hold {
  overflow: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 64px;
}

.layout-intransition .branding {
  display: none !important;
}
.layout-intransition .app-user-controls,
.layout-intransition .app-user-name {
  opacity: 0 !important;
}

.sidebar-compact .app-user,
.sidebar-compact .app-logo-text,
.sidebar-compact-big .app-user,
.sidebar-compact-big .app-logo-text {
  display: none;
}

[dir=rtl].app-admin-container.sidebar-closed .sidebar-panel {
  right: -15rem;
}
[dir=rtl].app-admin-container.sidebar-closed .branding {
  left: auto !important;
  right: -15rem;
}
[dir=rtl] .main-content-wrap {
  float: left;
}
[dir=rtl] .sidebar-panel {
  right: 0;
  left: auto !important;
}

.app-admin-container.sidebar-full.navigation-top .sidebar-panel {
  left: 0;
}

.app-admin-container.navigation-top .main-content-wrap {
  float: none;
  height: calc(100vh - 48px);
}
.app-admin-container.navigation-top .rightside-content-hold {
  max-width: 1400px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}
.app-admin-container.navigation-top .sidebar-panel {
  overflow: hidden;
  left: -15rem;
}

@media (max-width: 959px) {
  .app-admin-container.sidebar-full .main-content-wrap {
    width: 100% !important;
  }
}
.notification-number {
  position: absolute;
  top: 0;
  left: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}

#langToggle .mat-select-trigger {
  width: 80px;
  min-width: 80px;
}

.theme-list .mat-menu-item {
  width: 48px;
  height: 48px;
  padding: 5px;
}
.theme-list .mat-menu-item .egret-swatch {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}
.theme-list .mat-menu-item .active-icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto !important;
  color: #ffffff;
}

.topbar-button-right {
  margin-left: 1rem !important;
}

[dir=rtl] .topbar-button-right {
  margin-right: 1rem;
  margin-left: 0 !important;
}

.sidenav-hold {
  margin-top: 5px;
}
.sidenav-hold .menuitem-badge {
  padding: 3px 10px;
  line-height: 12px;
  color: #ffffff !important;
  font-weight: 400;
  border-radius: 4px;
  font-size: 12px;
  margin-right: 8px;
}
.sidenav-hold .icon-menu {
  padding: 0 24px;
  opacity: 1;
  transition: all 0.3s ease-in;
}
.sidenav-hold .icon-menu .icon-menu-item {
  display: inline-block;
}
.sidenav-hold .icon-menu .icon-menu-item button {
  min-width: auto;
  margin: 4px;
}
.sidenav-hold .icon-menu > .mat-divider {
  margin: 1rem 0;
}
.sidenav-hold .nav-item-sep {
  padding: 0;
  margin: 1rem 0;
}
.sidenav-hold .nav-item-sep .icon-menu-title {
  padding-left: 0px;
  margin-left: -8px;
}
.sidenav-hold .nav-item-sep span {
  padding: 16px 0 0 16px;
  display: block;
  font-size: 12px;
}
.sidenav-hold .sidenav li {
  cursor: pointer;
}
.sidenav-hold .sidenav li ul.submenu {
  max-height: 0;
  overflow: hidden;
  opacity: 1;
}
.sidenav-hold .sidenav li ul.submenu.lvl2 a, .sidenav-hold .sidenav li ul.submenu.lvl3 a {
  height: 44px;
}
.sidenav-hold .sidenav li ul.submenu.lvl2 a {
  padding: 0 16px 0 20px;
}
.sidenav-hold .sidenav li ul.submenu.lvl3 a {
  padding: 0 16px 0 30px;
}
.sidenav-hold .sidenav a {
  position: relative;
  width: 100%;
  padding: 0 16px 0 0;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  text-decoration: none;
}
.sidenav-hold .sidenav a .mat-icon:not(.menu-caret) {
  text-align: center;
  margin-right: 3px;
  height: 48px;
  width: 48px;
  line-height: 48px;
  border-left: 3px solid;
  border-color: transparent;
}
.sidenav-hold .sidenav a .menu-caret {
  font-size: 1rem;
  line-height: 1;
  height: 16px;
  width: 16px;
  transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}

.sidebar-full .sidenav-hold li ul {
  position: relative;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-full .sidenav-hold li ul::after, .sidebar-full .sidenav-hold li ul::before {
  content: "";
  left: 0;
  position: absolute;
  width: 100%;
  height: 2px;
  z-index: 3;
}
.sidebar-full .sidenav-hold li ul::after {
  background: linear-gradient(-180deg, rgba(0, 0, 0, 0.06), transparent);
  bottom: 0;
}
.sidebar-full .sidenav-hold li ul::before {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.1), transparent);
  top: 0;
}
.sidebar-full .sidenav-hold li.open, .sidebar-full .sidenav-hold li.open li.open {
  border-top: 1px solid #e1e1e1;
}
.sidebar-full .sidenav-hold li.open > div > ul, .sidebar-full .sidenav-hold li.open > ul {
  max-height: 1000px;
  background: rgba(0, 0, 0, 0.005);
}
.sidebar-full .sidenav-hold li.open > div > a .menu-caret, .sidebar-full .sidenav-hold li.open > a .menu-caret {
  transform: rotate(90deg);
}
.sidebar-full .sidenav-hold a {
  height: 48px;
}

.sidebar-compact .sidenav-hold .icon-menu,
.sidebar-compact-big .sidenav-hold .icon-menu {
  padding: 8px 0 0;
}
.sidebar-compact .sidenav-hold .icon-menu .nav-item-sep,
.sidebar-compact-big .sidenav-hold .icon-menu .nav-item-sep {
  display: none;
}
.sidebar-compact .sidenav-hold .nav-item-sep,
.sidebar-compact-big .sidenav-hold .nav-item-sep {
  margin: 1rem 0;
}
.sidebar-compact .sidenav-hold .nav-item-sep span,
.sidebar-compact-big .sidenav-hold .nav-item-sep span {
  display: none;
}
.sidebar-compact .sidenav-hold a,
.sidebar-compact-big .sidenav-hold a {
  padding: 0 !important;
}
.sidebar-compact .sidenav-hold li,
.sidebar-compact-big .sidenav-hold li {
  position: relative;
  z-index: 10001;
}
.sidebar-compact .sidenav-hold li .lvl1 > a > .mat-icon.menu-caret,
.sidebar-compact .sidenav-hold li .menuitem-badge,
.sidebar-compact-big .sidenav-hold li .lvl1 > a > .mat-icon.menu-caret,
.sidebar-compact-big .sidenav-hold li .menuitem-badge {
  display: none;
}
.sidebar-compact .sidenav-hold li:hover > div > ul.submenu, .sidebar-compact .sidenav-hold li:hover > ul.submenu,
.sidebar-compact-big .sidenav-hold li:hover > div > ul.submenu,
.sidebar-compact-big .sidenav-hold li:hover > ul.submenu {
  max-height: 1000px;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.4s cubic-bezier(0.35, 0, 0.25, 1);
}
.sidebar-compact .sidenav-hold li ul.submenu,
.sidebar-compact-big .sidenav-hold li ul.submenu {
  overflow: visible;
  position: absolute;
  left: 100%;
  top: 0;
  width: 200px;
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transform: translateY(10px);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
.sidebar-compact .sidenav-hold li ul.submenu.lvl2 > li > a, .sidebar-compact .sidenav-hold li ul.submenu.lvl3 > li > a,
.sidebar-compact-big .sidenav-hold li ul.submenu.lvl2 > li > a,
.sidebar-compact-big .sidenav-hold li ul.submenu.lvl3 > li > a {
  height: 36px;
  line-height: 36px;
  padding: 0 16px !important;
}

.sidebar-compact .icon-menu {
  width: 48px;
}
.sidebar-compact .icon-menu .icon-menu-item button {
  margin: 0 0 4px 0;
  padding: 0 12px;
}
.sidebar-compact .sidenav-hold .mat-icon:not(.menu-caret) {
  margin-right: 0;
}
.sidebar-compact .sidenav-hold .item-name.lvl1 {
  display: none;
}

.sidebar-compact-big .sidenav > li > div > a {
  text-align: center;
  display: block !important;
  padding: 16px 0;
}
.sidebar-compact-big .sidenav .mat-icon:not(.menu-caret) {
  height: 36px;
  width: 150px;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
}

.layout-intransition .sidenav ul.submenu {
  opacity: 0 !important;
}

[dir=rtl].sidebar-full .sidenav-hold a {
  padding: 0 0 0 16px;
}
[dir=rtl].sidebar-compact .sidenav-hold a, [dir=rtl].sidebar-compact-big .sidenav-hold a {
  padding: 0 !important;
}
[dir=rtl].sidebar-compact .sidenav-hold li ul.submenu, [dir=rtl].sidebar-compact-big .sidenav-hold li ul.submenu {
  left: auto !important;
  right: 100%;
}
[dir=rtl].sidebar-compact .sidenav-hold li ul.submenu.lvl2 > li > a, [dir=rtl].sidebar-compact .sidenav-hold li ul.submenu.lvl3 > li > a, [dir=rtl].sidebar-compact-big .sidenav-hold li ul.submenu.lvl2 > li > a, [dir=rtl].sidebar-compact-big .sidenav-hold li ul.submenu.lvl3 > li > a {
  padding: 0 16px !important;
}
[dir=rtl] .sidenav-hold .sidenav a {
  padding: 0 0 0 16px;
}
[dir=rtl] .sidenav-hold .sidenav a .mat-icon:not(.menu-caret) {
  border-left: none;
  border-right: 3px solid;
  margin-right: 1px;
  border-color: transparent;
}
[dir=rtl] .sidenav-hold .sidenav li ul.submenu.lvl2 > li > a {
  padding: 0 55px 0 16px;
}
[dir=rtl] .sidenav-hold .sidenav li ul.submenu.lvl3 > li > a {
  padding: 0 64px 0 16px;
}
[dir=rtl] .sidenav-hold .nav-item-sep .icon-menu-title {
  padding-right: 0 !important;
  margin: 0 -6px 0 0 !important;
}
[dir=rtl] .sidenav-hold .nav-item-sep span {
  padding: 16px 20px 0 0;
}

/*
* ALTERNATIVE STYLES
*/
.breadcrumb {
  display: block;
  margin: 0;
  padding: 0;
}
.breadcrumb li {
  list-style: none;
  float: left;
}
.breadcrumb li:not(:first-child):before {
  content: "/ ";
  padding: 0 8px;
}

.breadcrumb-bar {
  position: relative;
  width: 100%;
}
.breadcrumb-bar .breadcrumb {
  padding: 0 0.333rem;
  overflow: hidden;
}
.breadcrumb-bar .breadcrumb li {
  line-height: 40px;
}
.breadcrumb-bar .breadcrumb li:not(:first-child):before {
  padding: 0 8px;
}
.breadcrumb-bar .breadcrumb li a {
  font-weight: 400;
  font-size: 1rem;
}

.breadcrumb-title {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.333rem;
  line-height: 40px;
}
.breadcrumb-title .bc-title {
  font-size: 1.25rem;
  margin: 0;
  line-height: 40px;
}
.breadcrumb-title .breadcrumb {
  position: relative;
  margin-left: 24px;
}
.breadcrumb-title .breadcrumb::before {
  position: absolute;
  content: "";
  height: 24px;
  width: 2px;
  background: #6a6a6a;
  left: -12px;
  top: 8px;
}

[dir=rtl] .breadcrumb-bar .breadcrumb li,
[dir=rtl] .breadcrumb-title .breadcrumb li {
  float: right;
}
[dir=rtl] .breadcrumb-title .breadcrumb {
  margin-left: 0;
  margin-right: 24px;
}
[dir=rtl] .breadcrumb-title .breadcrumb::before {
  left: auto;
  right: -12px;
}

.notification-list {
  min-width: 240px;
}

.notification-list .notific-item a {
  outline: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notification-list .notific-item .message {
  font-size: 0.875rem !important;
}

.notification-list .notific-item .time {
  font-size: 0.75rem !important;
}

.branding {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  width: 100%;
  max-width: 15rem;
  height: 64px;
  line-height: 64px;
  top: 0;
  left: 0;
  z-index: 9999;
  color: #444;
  transition-delay: 0s;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.branding .app-logo {
  width: auto;
  height: 22px;
  margin-right: 8px;
}
.branding .app-logo-text {
  width: auto;
  height: 55px;
}

.app-user {
  text-align: center;
  width: 100%;
  padding: 0.5rem 0 0.4rem;
}
.app-user .app-user-photo {
  width: 72px;
  margin: 0 auto 8px;
  height: 72px;
  box-sizing: border-box;
  border: 1px solid rgba(120, 130, 140, 0.13);
  padding: 4px;
  border-radius: 50%;
}
.app-user .app-user-controls .mat-icon-button {
  margin: 0 2px;
}
.app-user img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.app-user .app-user-name {
  display: block;
  font-size: 1.3em;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.96);
}

[dir=rtl] .branding {
  top: 0;
  left: auto !important;
  right: 0;
}
[dir=rtl] .branding .app-logo {
  margin-right: 0;
  margin-left: 8px;
}

.topbar.mat-toolbar {
  position: relative;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  z-index: 999;
}
.topbar .mat-select-value {
  font-weight: 400;
  color: #ffffff;
}

.toggle-collapsed {
  transform: rotate(0deg);
  -webkit-transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
  transition: transform 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}

.sidebar-compact .toggle-collapsed {
  transform: rotate(180deg);
}

.search-bar .top-search-form {
  position: relative;
  background: #fff;
  border-radius: 40px;
  margin-right: 1rem;
  display: block;
  max-width: 220px;
  margin-left: 20px;
  box-shadow: inset 0 0 2px 2px rgba(136, 136, 136, 0.2);
}
.search-bar .top-search-form .material-icons {
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -12px;
  color: rgba(0, 0, 0, 0.87);
}
.search-bar .top-search-form input {
  font-size: 1rem;
  padding: 0.6rem 0.75rem;
  z-index: 2;
  cursor: text;
  text-indent: 30px;
  border: none;
  background: transparent;
  width: 100%;
  outline: 0;
}

.topnav:after {
  content: "";
  display: table;
  clear: both;
}
.topnav label.menu-toggle {
  height: 48px;
  width: 48px;
  box-sizing: border-box;
  padding: 12px;
  border-radius: 50%;
}
.topnav label.menu-toggle .mat-icon {
  font-size: 24px;
}
.topnav .toggle,
.topnav [id^=drop] {
  display: none;
}
.topnav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  position: relative;
}
.topnav ul:not(.menu) {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0), 0 4px 8px rgba(0, 0, 0, 0.28);
}
.topnav ul.menu {
  float: left;
  height: 48px;
  padding-right: 45px;
}
.topnav ul.menu > li {
  float: left;
}
.topnav ul.menu > li > div > a,
.topnav ul.menu > li > div > div {
  border-bottom: 2px solid;
  height: 48px;
  box-sizing: border-box;
  border-color: transparent;
  margin: 0 6px;
}
.topnav ul li {
  margin: 0px;
  display: inline-block;
}
.topnav a, .topnav label {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 20px;
  height: 44px;
  font-size: 0.875rem;
  text-decoration: none;
  box-sizing: border-box;
}
.topnav a .mat-icon, .topnav label .mat-icon {
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  width: 20px;
  margin-right: 2px;
}
.topnav ul li ul li:hover,
.topnav ul li ul li.open {
  background: #eeeeee;
}
.topnav ul ul {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  /* has to be the same number as the "line-height" of "nav a" */
  top: 48px;
  transform: translateY(-100px);
  transition: all 0.3s ease-in-out;
  z-index: -1;
}
.topnav ul li:hover > div > div > ul,
.topnav ul li:hover > div > ul {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}
.topnav ul ul li {
  width: 170px;
  float: none;
  display: list-item;
  position: relative;
}
.topnav ul ul ul {
  top: 0;
  left: 170px;
}
.topnav ul ul ul li {
  position: relative;
  top: 0;
}
.topnav li > a:after {
  content: " +";
}
.topnav li > a:only-child:after {
  content: "";
}

/* Media Queries
--------------------------------------------- */
@media all and (max-width: 768px) {
  .topnav {
    margin: 0;
    /* Hide Dropdowns by Default */
    /* Hide menus on hover */
  }
  .topnav .toggle + a {
    display: none;
  }
  .topnav .menu {
    opacity: 0;
    visibility: hidden;
    height: auto !important;
    width: 100%;
  }
  .topnav .menu li a {
    border: none !important;
  }
  .topnav .toggle {
    display: flex;
    text-decoration: none;
    border: none;
  }
  .topnav ul {
    overflow: hidden;
    max-height: 0px;
    transform: translateY(0px) !important;
    transition: max-height 0.3s ease-in-out;
  }
  .topnav [id^=drop]:checked + ul {
    opacity: 1;
    visibility: visible;
    max-height: 2000px;
  }
  .topnav [id^=drop]:checked + ul.menu {
    max-height: 300px;
    overflow-y: scroll;
  }
  .topnav ul li {
    position: relative;
    opacity: 1;
    visibility: visible;
    width: 100%;
    z-index: 1;
  }
  .topnav ul ul .toggle,
.topnav ul ul a {
    padding: 0 40px;
  }
  .topnav ul ul ul a {
    padding: 0 80px;
  }
  .topnav ul li ul li .toggle,
.topnav ul ul a,
.topnav ul ul ul a {
    padding: 14px 20px;
  }
  .topnav ul ul {
    float: none;
    position: relative;
    top: 0;
    left: 0;
    box-shadow: none !important;
    z-index: 1;
  }
  .topnav ul li:hover > div > div > ul,
.topnav ul li:hover > div > ul {
    opacity: 0;
    visibility: hidden;
  }
  .topnav ul ul li {
    opacity: 1;
    visibility: visible;
    width: 100%;
  }
  .topnav ul ul ul {
    left: 0;
  }
  .topnav ul ul ul li {
    position: static;
  }
}
@media all and (max-width: 330px) {
  .topnav ul li {
    display: block;
    width: 94%;
  }
}
[dir=rtl] .topnav a .mat-icon, [dir=rtl] .topnav label .mat-icon {
  margin-right: 0;
  margin-left: 2px;
}

.header-topnav {
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  position: relative;
  z-index: 999;
}
.header-topnav .container {
  padding: 0;
}
.header-topnav .topbar-branding {
  float: left;
  height: 48px;
  padding: 8px;
  margin: 0 8px;
}
.header-topnav .topbar-branding img {
  height: 100%;
  width: auto;
}
.header-topnav .topnav {
  display: flex;
  align-items: center;
}
.header-topnav .header-topnav-right {
  float: right;
  height: 48px;
  display: flex;
  align-items: center;
  padding-right: 0.67rem;
}

@media (max-width: 959px) {
  .header-topnav-right {
    position: absolute;
    right: 6px;
    top: 0;
  }
}
[dir=rtl] .header-topnav .topnav {
  flex-direction: row-reverse;
}

/*---- Session (Sign in, sign up, forgot, lockscreen) -----*/
.page-wrap {
  display: flex;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
}

.session-progress {
  position: relative;
  bottom: -5px;
  z-index: 9999;
}

.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.session-form-hold > .mat-card {
  margin: 0;
}

.session-lockscreen {
  max-width: 320px;
}

.lockscreen-user {
  text-align: center;
  margin-bottom: 1rem;
  display: flow-root;
  overflow: hidden;
}

.lockscreen-face {
  max-width: 72px;
  max-height: 72px;
  border-radius: 50%;
  margin-bottom: 0.5rem;
}

/*---- Inbox -----*/
.inbox-sidenav {
  width: 15rem;
  padding: 0.333rem;
  background: transparent !important;
}

.inbox-sidenav .inbox-nav-list .mat-list-item-content > a > span {
  font-size: 0.875rem;
}

.inbox-sidenav .mat-list-item-content .mat-icon {
  margin-right: 8px;
}

.inbox-toolbar {
  height: 56px !important;
}

.inbox-toolbar .mat-toolbar-row {
  height: 56px !important;
}

.show-on-open {
  display: none !important;
}

.mat-expanded .show-on-open {
  display: inherit !important;
}
.mat-expanded .hide-on-open {
  display: none !important;
}
.mat-expanded .mat-expansion-panel-header {
  margin-bottom: 1rem;
}

.messages-wrap {
  padding: 0.333rem;
  min-height: 800px;
}
.messages-wrap .mat-expansion-panel-header-title {
  align-items: center;
}
.messages-wrap .mat-expansion-panel-header-description {
  align-items: center;
}
.messages-wrap .mail-checkbox.mat-checkbox {
  position: relative;
  width: 20px;
  height: 22px;
  overflow: hidden;
  margin-right: 8px;
}
.messages-wrap .mail-checkbox.mat-checkbox .mat-checkbox-layout {
  position: absolute;
  top: 0;
  left: 0;
}
.messages-wrap .inbox-face {
  height: 36px;
  width: 36px;
  border-radius: 50%;
}

[dir=rtl] .inbox-sidenav .mat-list-item-content .mat-icon {
  margin-right: 0;
  margin-left: 8px;
}
[dir=rtl] .messages-wrap .mail-checkbox {
  margin-right: 0;
  margin-left: 0.5rem;
}
[dir=rtl] .messages-wrap .mail-checkbox .mat-checkbox-inner-container {
  margin-left: 0;
}

@media (max-width: 959px) {
  .inbox-sidenav {
    background: inherit !important;
  }
}
/*---- Calendar -----*/
.card-title-text.calendar-title {
  padding: 0.5rem 1.5rem !important;
}

.cal-top-col {
  width: 33.3333%;
  float: left;
}

.cal-event-action .material-icons {
  vertical-align: sub;
  margin: 6px;
}

.cal-open-day-events {
  box-shadow: none !important;
}

.calendar-form-dialog .mat-dialog-container {
  padding: 0;
}

.color-picker-input.mat-input-element {
  padding: 4px 0 !important;
}

@media (max-width: 767px) {
  .cal-top-col {
    width: 100%;
  }
}
/*---- Chats -----*/
.chat-sidenav {
  width: 15rem;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  overflow: hidden !important;
}

.chat-sidebar-toolbar,
.chat-toolbar,
.chat-sidebar-toolbar .mat-toolbar-row,
.chat-toolbar .mat-toolbar-row {
  min-height: 56px !important;
  height: 56px !important;
}

.chat-sidebar-scroll {
  position: relative;
  height: calc(100% - 56px);
  overflow-y: scroll;
}

.chat-intro {
  height: calc(100vh - 240px);
}

.chats-wrap .conversations-hold {
  padding-top: 1rem;
  position: relative;
  height: calc(100vh - 330px);
  overflow-y: scroll;
}

.chats-wrap .conversations-hold .single-conversation {
  overflow: hidden;
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.chats-wrap .conversations-hold .single-conversation .toolbar-avatar {
  vertical-align: bottom;
  margin-right: 1rem;
}

.chats-wrap .conversations-hold .single-conversation .chat-username {
  margin: 0 0 0.625rem;
  font-size: 0.875rem;
}

.chats-wrap .conversations-hold .single-conversation .conversation-msg {
  padding: 0.48rem 0.94rem;
  display: inline-block;
  border-radius: 4px;
}

.chats-wrap .conversations-hold .single-conversation.sender .conversation-msg {
  background: #ffffff;
}

.chats-wrap .conversations-hold .single-conversation .chat-date {
  font-size: 11px;
  padding: 2px;
  margin: 0;
  clear: both;
}

.chats-wrap .chat-input-actions {
  padding: 0.6rem;
}

[dir=rtl] .chat-sidenav {
  border-right: none;
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
[dir=rtl] .chats-wrap .conversations-hold .single-conversation .toolbar-avatar {
  margin-right: 0;
  margin-left: 1rem;
}

/*---- Gallery -----*/
.app-gallery .mat-figure .gallery-control-wrap {
  position: absolute;
  width: 100%;
  height: 40px;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.35);
  opacity: 0;
  transition: opacity 0.3s ease-in;
  -webkit-transition: opacity 0.3s ease-in;
}

.app-gallery img {
  max-width: 100%;
}

.app-gallery .mat-figure:hover .gallery-control-wrap {
  opacity: 1;
}

.app-gallery .mat-figure .gallery-control-wrap .gallery-control {
  padding: 0 0 0 1rem;
}

.app-gallery .mat-figure .gallery-control-wrap .gallery-control .photo-detail {
  margin: 0;
  color: #FEFEFE;
  line-height: 40px;
}

.app-gallery .mat-figure .gallery-control-wrap .gallery-control .mat-icon {
  color: #fff;
}

[dir=rtl] .app-gallery .mat-figure .gallery-control-wrap .gallery-control .photo-detail {
  margin-right: 1rem;
}

/*---- Plans & Pricings -----*/
.plan-pricing .mat-list-item .mat-list-item-content {
  display: inline-flex !important;
  text-align: center;
  font-size: 0.875rem !important;
}

/*---- Users -----*/
.user-card .card-title-text {
  padding: 0.5rem 1.5rem !important;
  align-items: center;
}

.user-card .user-details > p:last-child {
  margin-bottom: 0;
}

.user-card .user-details .mat-icon {
  vertical-align: top;
  margin-right: 0.5rem;
}

/*------- Profile ------*/
.profile-sidebar {
  box-sizing: border-box;
}

.profile-sidebar .propic {
  width: 100%;
  margin-bottom: 6px;
}

.profile-sidebar .propic img {
  width: 50%;
  height: auto;
  border-radius: 50%;
}

.profile-sidebar .profile-title .main-title {
  font-size: 1.5rem;
}

.profile-sidebar .profile-nav {
  margin-left: -24px;
  margin-right: -24px;
}

.profile-sidebar .profile-nav .mat-list-item .mat-icon {
  margin-right: 8px;
}

.timeline {
  position: relative;
  overflow: hidden;
}

.timeline::before {
  content: "";
  position: absolute;
  top: 40px;
  bottom: 0;
  left: 24px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.12);
}

.timeline .timeline-item {
  width: 100%;
  overflow: hidden;
  margin-bottom: 24px;
}

.timeline .timeline-badge {
  float: left;
  position: relative;
  margin-right: 30px;
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.timeline .timeline-badge img {
  max-width: 100%;
}

.timeline .timeline-badge .icon-badge {
  text-align: center;
  width: 48px;
  height: 48px;
  line-height: 48px;
}

.timeline .timeline-body-top {
  margin-bottom: 8px;
}

.timeline .timeline-body {
  float: right;
  width: calc(100% - 78px);
}

.timeline .timeline-body-content img {
  border-radius: 4px;
  max-width: 100%;
}

.timeline .timeline-body-content > :last-child {
  margin-bottom: 8px;
}

[dir=rtl] .profile-sidebar .profile-nav .mat-list-item .mat-icon {
  margin-right: 0;
  margin-left: 8px;
}

.product-rating {
  display: flex;
  align-items: center;
  margin-left: -5px;
}

h1.title {
  font-size: 1.8rem;
  margin: 0 0 1rem;
}

.p-line {
  font-size: 0.813rem;
  margin-bottom: 4px;
}

.option-label {
  margin-bottom: 6px;
  display: block;
}

.product-search,
.quantity-input {
  border: 0;
  height: 32px;
  border-radius: 2px;
  padding-left: 8px;
  font-size: 14px;
  width: 260px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.08), 0px 1px 3px 0px rgba(0, 0, 0, 0);
  transition: all 300ms cubic-bezier(0.35, 0, 0.25, 1);
}
.product-search:focus,
.quantity-input:focus {
  outline: none;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.quantity-input {
  width: 80px;
}

.shop-wrap {
  margin: 1rem 0.33rem 0.33rem;
  overflow: hidden;
  min-height: 750px;
}

.shop-sidebar {
  width: 15rem;
  background: transparent !important;
}

.shop-filters-wrap {
  margin-top: 7px;
  padding: 0 0.5rem 0 0.333rem;
}
.shop-filters-wrap .product-categories {
  list-style: none;
  margin: 0;
  padding: 0;
}
.shop-filters-wrap .product-categories li {
  cursor: pointer;
  padding: 5px;
  text-transform: capitalize;
}

.shop-top-toolbar {
  margin-bottom: 0.5rem;
  padding: 0 8px;
}

.product-search-wrap .product-search {
  width: 260px;
  margin: 6px 0 0;
}

.product-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  min-height: 750px;
}
.product-container .products-pagination {
  width: 100%;
  text-align: center;
  margin: 1.5rem 0;
  align-self: flex-end;
  justify-content: flex-end;
}
.product-container .products-pagination ul {
  padding: 0;
  margin: 0;
}
.product-container .products-pagination .ngx-pagination .current {
  background: #e6e6e6;
  color: rgba(0, 0, 0, 0.87);
}
.product-container .product-wrap {
  width: 33.333%;
  float: left;
}
.product-container .product-wrap .product-inner {
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  margin: 8px;
  transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.product-container .product-wrap .product-inner:hover {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.product-container .product-wrap .product-inner .product-badge {
  color: #fff;
  text-align: center;
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  z-index: 100;
  height: 64px;
  width: 64px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 8px;
  transform: rotate(-30deg);
}
.product-container .product-wrap .product-inner .product-badge span {
  font-weight: 500;
  line-height: 1.1;
}
.product-container .product-wrap .product-inner .featured-img {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-height: 180px;
}
.product-container .product-wrap .product-inner .info-wrap {
  display: flex;
  flex-direction: column;
}
.product-container .product-wrap .product-inner .info-wrap .main-info {
  width: 100%;
  margin-bottom: 1rem;
  min-height: 150px;
}
.product-container .product-wrap .product-inner .info-wrap .main-info .title {
  margin: 0;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 4px;
  cursor: pointer;
}
.product-container .product-wrap .product-inner .info-wrap .main-info .title:active {
  outline: none;
}
.product-container .product-wrap .product-inner .info-wrap .actions {
  width: 100%;
  display: flex;
  flex-direction: row;
}
.product-container .product-wrap .product-inner .info-wrap .actions div {
  flex: 50%;
  display: flex;
}
.product-container .product-wrap .product-inner .info-wrap .actions .price-rating {
  flex-direction: column;
  justify-content: flex-start;
}
.product-container .product-wrap .product-inner .info-wrap .actions .price-rating .price {
  align-items: center;
}
.product-container .product-wrap .product-inner .info-wrap .actions .price-rating .price span {
  font-size: 1.2rem;
  margin-right: 4px;
}
.product-container .product-wrap .product-inner .info-wrap .actions .add-to-cart {
  align-items: center;
}
.product-container .product-wrap .product-inner ul {
  margin: 0;
  padding: 0 0 0 8px;
  list-style: none;
}
.product-container .product-wrap .product-inner ul li {
  margin-bottom: 4px;
  display: flex;
}
.product-container .product-wrap .product-inner ul li .mat-icon {
  margin-right: 8px;
}
.product-container .product-wrap .product-inner .more-info-wrap {
  display: none;
}

.product-details-wrap .gallery-photo {
  min-height: 400px;
  width: calc(100% - 80px);
  float: left;
  padding-right: 1rem;
  margin-bottom: 1rem;
}
.product-details-wrap .gallery-photo img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}
.product-details-wrap .gallery-thumbnails {
  width: 80px;
  float: left;
  padding-right: 0.667rem;
}
.product-details-wrap .gallery-thumbnails img {
  width: 100%;
  border-radius: 2px;
  display: inline-block;
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.product-details-wrap .gallery-thumbnails img.active {
  opacity: 0.6;
}

.cart-table {
  width: 100%;
  border-collapse: separate;
  border-radius: 2px;
  margin-bottom: 1.5rem;
  border-spacing: 0;
}
.cart-table .cart-thumbnail {
  height: 44px;
  width: auto;
  border-radius: 2px;
}
.cart-table thead tr {
  border-collapse: separate;
  background: rgba(0, 0, 0, 0.03);
}
.cart-table thead tr th {
  border-collapse: separate;
  text-align: left;
  padding: 1rem;
  font-weight: 500;
  letter-spacing: 0.1px;
}
.cart-table tbody tr td {
  text-align: left;
  padding: 16px;
}

@media (max-width: 1100px) {
  .product-container .product-wrap {
    width: 50%;
  }
}
@media (max-width: 960px) {
  .shop-filters-wrap {
    margin: 0;
    padding: 0;
  }
}
@media (max-width: 680px) {
  .product-container .product-wrap {
    width: 100%;
  }

  .product-details-wrap .gallery-photo {
    min-height: auto;
  }

  /* Force table to not be like tables anymore */
  table, thead, tbody, th, td, tr {
    display: block;
  }

  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }

  tr:last-child {
    border-bottom: none;
  }

  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.03);
    position: relative;
    padding-left: 50%;
  }

  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 6px;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
}
@media (min-width: 1100px) {
  .product-container.list-view .product-wrap {
    width: 100%;
    padding-right: 0;
  }
  .product-container.list-view .product-wrap .product-inner {
    flex-direction: row;
  }
  .product-container.list-view .product-wrap .featured-img {
    flex: 3 0;
  }
  .product-container.list-view .product-wrap .featured-img img {
    width: 100%;
    max-width: 100%;
    min-height: 100%;
  }
  .product-container.list-view .product-wrap .info-wrap {
    flex-direction: column;
    flex: 2 0;
  }
  .product-container.list-view .product-wrap .info-wrap .actions {
    align-self: flex-end;
    justify-content: flex-end;
    margin-top: auto;
  }
  .product-container.list-view .product-wrap .more-info-wrap {
    display: flex;
    align-items: stretch;
    flex: 2 0;
    position: relative;
  }
  .product-container.list-view .product-wrap .more-info-wrap .more-info {
    width: 100%;
    padding-left: 1rem;
    border-left: 1px solid #e6e6e6;
  }
}
[dir=rtl] .product-search,
[dir=rtl] .quantity-input {
  padding-right: 8px;
}
[dir=rtl] .product-container.list-view .product-wrap .more-info-wrap .more-info {
  border: 0;
  padding-left: 0;
}
[dir=rtl] .product-container .product-wrap .product-inner .product-badge {
  left: auto;
  right: 20px;
}
[dir=rtl] .product-container .product-wrap .product-inner ul li .mat-icon {
  margin-right: 0;
  margin-left: 8px;
}
[dir=rtl] .product-details-wrap .gallery-photo {
  padding: 0;
}
[dir=rtl] .product-details-wrap .product-content-wrap {
  padding: 0 1rem;
}

.home-section {
  padding: 80px 0;
}

.scrollable {
  position: relative;
  width: 100%;
  height: 100vh;
}

.section-intro {
  padding: 40px 0 60px;
  background: #ffffff;
}
.section-intro h1 {
  font-size: 32px;
  margin: 2rem 0 1rem;
  font-weight: 300;
}
.section-intro p {
  font-size: 16px;
  max-width: 450px;
  margin: 0 auto 1.4rem;
}
.section-intro img {
  display: block;
  margin: 0 auto;
}

.section-demos {
  position: relative;
  background: #ffffff;
}
.section-demos .demo-box h3 {
  margin: 0 0 1.4rem;
  font-size: 28px;
  font-weight: 300;
}
.section-demos .demo-box img {
  width: 100%;
  height: auto;
  float: left;
}
.section-demos .demo-box .screenshot {
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 2rem;
  min-height: 180px;
  cursor: pointer;
}

/* 
  Only Required if you want to use Angular Landing
  (https://themeforest.net/item/angular-landing-material-design-angular-app-landing-page/21198258)
*/